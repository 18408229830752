import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PHMainMenu } from '@abc-intelligence/abc-ui';

import { envConfig } from 'base/config';
import { navigateToSignIn } from 'utils';
import { useApi, useStore, usePGPermissions } from 'hooks';
import { LOG_OUT_URL } from 'base/api';
import { getMainMenuOptions } from '../../consts';
import { UserI } from 'base/types';

export interface MenuProps {
  user: UserI | null;
}

const Menu: React.FC<MenuProps> = ({ user }) => {
  const { t } = useTranslation();
  const { bindActions } = useStore();
  const { sendRequest } = useApi();
  const { canAccessAdminPortal } = usePGPermissions();

  const displayInitials = () => {
    if (user?.user_first_name && user?.user_last_name) {
      return (
        user?.user_first_name.substring(0, 1) +
        user?.user_last_name.substring(0, 1)
      );
    } else if (user?.user_first_name) {
      return user?.user_first_name.substring(0, 2);
    } else if (user?.user_last_name) {
      return user?.user_last_name?.substring(0, 2);
    } else {
      return '';
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      await sendRequest({
        baseURL: `${envConfig.BASE_API}${LOG_OUT_URL}`,
        method: 'POST',
      });
      navigateToSignIn();
    } catch (err) {
      const { setError } = bindActions();
      setError(t('error.generalError'));
    }
  }, [sendRequest, bindActions, t]);

  return (
    <PHMainMenu
      menuInitials={displayInitials()}
      options={[
        ...getMainMenuOptions(canAccessAdminPortal()),
        {
          label: t('navbar.mainMenu.signOut'),
          value: 'signOut',
          onClick: () => handleLogout(),
        },
      ]}
      menuHorizontalPlacement={'left'}
    />
  );
};

export default Menu;
