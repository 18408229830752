import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import logger from 'redux-logger';

import animalReducer from './reducers/animal/animalReducer';
import errorReducer from './reducers/error/errorReducer';
import loadingReducer from './reducers/loading/loadingReducer';
import successReducer from './reducers/success/successReducer';
import userReducer from './reducers/user/userReducer';
import inputValidationReducer from './reducers/inputValidation/inputValidationReducer';
import consultReducer from './reducers/consult/consultReducer';
import buttonBoardReducer from './reducers/buttonBoards/buttonBoards';

const reducer = {
  animal: animalReducer,
  error: errorReducer,
  loading: loadingReducer,
  success: successReducer,
  user: userReducer,
  inputValidation: inputValidationReducer,
  consult: consultReducer,
  btnBoard: buttonBoardReducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.REACT_APP_STAGE !== 'prod',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
