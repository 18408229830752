import React from 'react';

import { Tooltip } from 'components';
import { BaseTooltipProps } from 'components/Tooltip/Tooltip';
import { InfoIcon } from 'base/assets/react-icons-lib';
import styled from 'styled-components';

const InfoMarkIcon = styled(InfoIcon)`
  cursor: pointer;
`;

interface InfoMarkProps extends BaseTooltipProps {
  message: string;
}

const InfoMark: React.FC<InfoMarkProps> = ({
  message,
  direction,
  disable,
  className,
  type,
  textFontSize,
  maxWidth,
}) => {
  if (!message) return null;

  return (
    <Tooltip
      message={message}
      direction={direction || 'right'}
      disable={disable}
      className={className}
      type={type}
      textFontSize={textFontSize}
      maxWidth={maxWidth}
    >
      <InfoMarkIcon />
    </Tooltip>
  );
};

export default InfoMark;
