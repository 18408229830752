import styled from 'styled-components';

import { CloseIcon } from 'base/assets/react-icons-lib';
import { ModalHeader } from '../common';

export const Container = styled(ModalHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.span`
  font-size: 2rem;
  font-weight: 500;
`;

export const Close = styled(CloseIcon)`
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
`;
