import { useContext, useState, useEffect, useCallback } from 'react';
import { ReactReduxContext, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from 'base/store';
import * as actions from 'base/store/actions';

const useStore = () => {
  const { store } = useContext(ReactReduxContext);
  const { getState, subscribe } = store;
  const [storeState, setStoreState] = useState<RootState>(getState());
  const dispatch = useDispatch();

  const bindActions = useCallback(
    () => bindActionCreators(actions, dispatch),
    [dispatch]
  );

  useEffect(
    () => subscribe(() => setStoreState(getState())),
    [getState, subscribe]
  );

  return { state: storeState, bindActions };
};

export default useStore;
