import { InputValidationStateI, InputValidationPayloadI } from 'base/types';

export const inputValidationHelper = (
  state: InputValidationStateI,
  payload: InputValidationPayloadI
) => {
  if (payload.errorTranslationCode === '') {
    delete state[payload.validationKey];
  } else {
    state[payload.validationKey] = payload.errorTranslationCode;
  }
};
