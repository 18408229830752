import styled from 'styled-components';
import { rgba } from 'polished';
import { Modal as BaseModal } from '@abc-intelligence/abc-ui';

export const SECTION_PADDING = '1.5rem';

export const Modal = styled(BaseModal)`
  background-color: ${(p) => rgba(p.theme.colors.main900, 0.2)};
`;

export const ModalHeader = styled.div`
  padding: ${() => `${SECTION_PADDING}`};
  border-bottom: 1px solid ${(p) => p.theme.colors.primaryLightGray};
`;

export const ModalFooter = styled.div`
  padding: ${() => `${SECTION_PADDING}`};
  border-top: 1px solid ${(p) => p.theme.colors.primaryLightGray};
`;

export const ModalBody = styled.div`
  padding: ${() => `${SECTION_PADDING}`};
`;
