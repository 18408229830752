import styled, { css } from 'styled-components';

export const Table = styled.table`
  border: 1px solid ${(p) => p.theme.colors.primaryDarkGray};
  border-collapse: collapse;
  min-width: 50%;
`;

export const TableHead = styled.thead`
  background: ${(p) => p.theme.colors.primaryLightGray};
  border-bottom: 1px solid ${(p) => p.theme.colors.primaryDarkGray};
`;

export const TableBody = styled.tbody`
  & > tr {
    border-bottom: 1px solid ${(p) => p.theme.colors.primaryDarkGray};
  }

  tr:last-child {
    border: none;
  }
`;

const TdThBaseStyle = css`
  border: none;
  padding: 0.5rem 1rem;
  white-space: nowrap;
`;

export const TableTd = styled.td`
  ${TdThBaseStyle}
`;

export const TableTh = styled.th`
  ${TdThBaseStyle}
  color: ${(p) => p.theme.colors.primaryBlue};
  font-weight: 500;
`;
