import React, { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './OpacityTransition.css';

interface OpacityTransitionProps {
  isIn: boolean;
  children: ReactNode;
}

const OpacityTransition: React.FC<OpacityTransitionProps> = ({
  isIn,
  children,
}) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isIn}
      timeout={200}
      classNames={'fade'}
      unmountOnExit
    >
      <div ref={nodeRef}>{children}</div>
    </CSSTransition>
  );
};

export default OpacityTransition;
