import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  gap: 1rem;

  & > p {
    padding-bottom: 0.35rem;
  }
`;

interface RadioButtonProps {
  label: string;
  value: string;
  checked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  checked,
  onChange,
}) => {
  return (
    <Container>
      <input type="radio" value={value} checked={checked} onChange={onChange} />
      <p>{label}</p>
    </Container>
  );
};

export default RadioButton;
