import cloneDeep from 'lodash.clonedeep';

import { FrontPageStateI, FrontPageStateActionsT } from './types';

const frontPageReducer = (
  state: FrontPageStateI,
  action: FrontPageStateActionsT
): FrontPageStateI => {
  const clonedState = cloneDeep(state);

  switch (action.type) {
    case 'setInitialState':
      return {
        ...clonedState,
        ...action.payload,
      };
    case 'setLastRefreshTime':
      return {
        ...clonedState,
        lastRefreshTime: action.payload,
      };

    case 'setConsultList':
      return {
        ...clonedState,
        hospitalConsultList: action.payload.hospitalConsultList,
        waitingRoomConsultList: action.payload.waitingRoomConsultList,
      };
    case 'setAppointments':
      const { appointments, shifts, shiftsText } = action.payload;
      return {
        ...clonedState,
        appointments,
        dropdownValues: {
          ...clonedState.dropdownValues,
          shifts,
        },
        shiftsText,
        appointmentsLoading: false,
      };
    case 'setAllDropdownValues':
      return {
        ...clonedState,
        dropdownValues: action.payload,
      };
    case 'setDropdownValue':
      const { dropdownKey, newValue } = action.payload;
      return {
        ...clonedState,
        dropdownValues: {
          ...clonedState.dropdownValues,
          [dropdownKey]: newValue,
        },
      };
    case 'setDropdownOptions':
      return {
        ...clonedState,
        dropdownOptions: action.payload,
      };
    case 'setPageLoading':
      return {
        ...clonedState,
        pageLoading: action.payload,
      };
    case 'setAppointmentsLoading':
      return {
        ...clonedState,
        appointmentsLoading: action.payload,
      };
    case 'setPin':
      const { hospitalConsultList } = clonedState;
      hospitalConsultList.forEach((hc) => {
        if (hc.consult_id === action.payload) {
          hc.is_pinned = !hc.is_pinned;
        }
      });
      return clonedState;
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

export default frontPageReducer;
