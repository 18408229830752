import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserI } from 'base/types';

const INITIAL_STATE = null;

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE as UserI | null,
  reducers: {
    setUser: (state, { payload }: PayloadAction<UserI>) => payload,
    removeUser: () => null,
  },
});

export const { setUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
