import { useCallback } from 'react';
import { APP_KEYS } from '@abc-intelligence/abc-ui';

import useUserAbility from 'hooks/useUserAbility/useUserAbility';
import useStore from 'hooks/useStore/useStore';
import { UserI } from 'base/types';

enum PermissionActionEnum {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  access = 'access',
}

const usePGPermissions = (userProp?: UserI | null) => {
  const {
    state: { user },
  } = useStore();
  const { ability, subject } = useUserAbility(
    (userProp || user)?.user_permissions
  );

  const canAccessApp = useCallback(
    () =>
      ability.can(PermissionActionEnum.access, subject(APP_KEYS.PET_GENUS, {})),
    [ability, subject]
  );

  const canAccessAdminPortal = useCallback(
    () => ability.can(PermissionActionEnum.access, subject(APP_KEYS.ADMIN, {})),
    [ability, subject]
  );

  return {
    canAccessApp,
    canAccessAdminPortal,
  };
};

export default usePGPermissions;
