import { useTranslation } from 'react-i18next';

import {
  getEstimateEmailURL,
  getConsentEmailURL,
  getConsentPrintURL,
} from 'base/api/urls';
import { useGetActiveConsult, useApi, useStore } from 'hooks';
import { useCallback } from 'react';

const useBtnsApi = () => {
  const { sendRequest } = useApi();
  const activeConsult = useGetActiveConsult();
  const { bindActions } = useStore();
  const { t } = useTranslation();

  const emailEstimateHandler = useCallback(async () => {
    const { estimate, consult_id } = activeConsult;
    if (!estimate) return;

    try {
      const url = getEstimateEmailURL(consult_id as number);
      const response = await sendRequest({
        url,
        method: 'POST',
        data: {
          estimate,
        },
      });

      return response.success;
    } catch (err) {
      const { setError } = bindActions();
      setError(t(`error.generalError`));
      return false;
    }
  }, [activeConsult, sendRequest, bindActions, t]);

  const emailConsentsHandler = useCallback(async () => {
    const { consent_form, consult_id } = activeConsult;
    if (!consent_form) return;

    try {
      const url = getConsentEmailURL(consult_id as number);
      const response = await sendRequest({
        url,
        method: 'POST',
        data: {
          email: consent_form.email,
          procedure: consent_form.procedure,
          preferred_phone_number: consent_form.preferred_phone_number,
        },
      });
      return response.success;
    } catch (err) {
      const { setError } = bindActions();
      setError(t(`error.generalError`));
      return false;
    }
  }, [activeConsult, sendRequest, bindActions, t]);

  const printConsentHandler = useCallback(async () => {
    const { consent_form, consult_id } = activeConsult;
    if (!consent_form) return;

    try {
      const url = getConsentPrintURL(consult_id as number);
      const data = await sendRequest({
        url,
        method: 'POST',
        data: {
          email: consent_form.email,
          procedure: consent_form.procedure,
          preferred_phone_number: consent_form.preferred_phone_number,
        },
        responseType: 'blob',
      });
      return data;
    } catch (err) {
      const { setError } = bindActions();
      setError(t(`error.generalError`));
      return null;
    }
  }, [activeConsult, sendRequest, bindActions, t]);

  return {
    emailConsentsHandler,
    printConsentHandler,
    emailEstimateHandler,
  };
};

export default useBtnsApi;
