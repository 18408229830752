import React, { useState } from 'react';

import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
} from 'base/assets/react-icons-lib';
import {
  Container,
  Title,
  TitleExtraContainer,
  Content,
  SectionPairTitleDefault,
} from './Section.style';

interface SectionProps extends React.ComponentPropsWithRef<'div'> {
  sectionTitle?: string;
  subtitle?: string;
  customTitle?: React.ReactNode;
  extraInfo?: React.ReactNode;
  collapsible?: boolean;
  isCollapsed?: boolean;
  deletable?: boolean;
  innerSection?: boolean;
  deleteHandler?: () => void;
  contentHeight?: string;
  classNameForContent?: boolean;
  hiddenScrollbar?: boolean;
}

const Section: React.FC<SectionProps> = ({
  sectionTitle,
  subtitle,
  customTitle,
  children,
  extraInfo,
  collapsible,
  isCollapsed = true,
  deletable,
  innerSection,
  deleteHandler,
  contentHeight,
  className,
  classNameForContent,
  hiddenScrollbar,
}) => {
  const [collapsed, setCollapsed] = useState<boolean | undefined>(
    isCollapsed ? true : false
  );

  return (
    <Container className={!classNameForContent ? className : ''}>
      <Title collapsed={collapsed} innerSection={innerSection}>
        {customTitle || (
          <SectionPairTitleDefault>
            <span>{sectionTitle}</span>
            <span>{subtitle}</span>
          </SectionPairTitleDefault>
        )}
        <TitleExtraContainer>
          {extraInfo}
          {collapsible && collapsed !== undefined ? (
            collapsed ? (
              <ChevronUpIcon onClick={() => setCollapsed(false)} />
            ) : (
              <ChevronDownIcon onClick={() => setCollapsed(true)} />
            )
          ) : null}
          {deletable && <CloseIcon onClick={deleteHandler} />}
        </TitleExtraContainer>
      </Title>
      <Content
        collapsible={collapsible}
        collapsed={collapsed}
        contentHeight={contentHeight}
        className={classNameForContent ? className : ''}
        hiddenScrollbar={hiddenScrollbar}
      >
        {children}
      </Content>
    </Container>
  );
};

export default Section;
