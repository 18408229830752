import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnimalStateI } from 'base/types';

const INITIAL_STATE = null;

export const animalSlice = createSlice({
  name: 'animal',
  initialState: INITIAL_STATE as AnimalStateI | null,
  reducers: {
    setAnimal: (state, { payload }: PayloadAction<{ animal: AnimalStateI }>) =>
      payload.animal,
  },
});

export const { setAnimal } = animalSlice.actions;
export default animalSlice.reducer;
