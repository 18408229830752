const CONSULTS_LINK_REG = /\/home$/;
export const isInFrontPage = (path: string) => !!path.match(CONSULTS_LINK_REG);

const DOCUMENTATION_LINK_REG = /\/documentation/;
export const isItDocumentationPage = (path: string) =>
  !!path.match(DOCUMENTATION_LINK_REG);

const DASHBOARD_LINK_REG = /\/consult\/[0-9]+\/dashboard/;
export const isItDashboardPage = (path: string) =>
  !!path.match(DASHBOARD_LINK_REG);

export const getLastSegment = (path: string) =>
  path.substring(path.lastIndexOf('/') + 1);
