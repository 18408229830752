import { useMemo } from 'react';
import { createMongoAbility, subject } from '@casl/ability';

import { UserI } from 'base/types';

const useUserAbility = (permissions: UserI['user_permissions']) => {
  const ability = useMemo(
    () => createMongoAbility(permissions || []),
    [permissions]
  );

  return { ability, subject };
};

export default useUserAbility;
