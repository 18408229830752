import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router';

import { getLastSegment } from 'utils';
import { useStore } from 'hooks';
import { DashboardPages } from 'consts';
import { OrderAllStateI } from 'base/types';

const useOrderAll = () => {
  const [isInOrders, setIsInOrders] = useState(false);
  const location = useLocation();
  const { bindActions } = useStore();

  useEffect(() => {
    setIsInOrders(getLastSegment(location.pathname) === DashboardPages.ORDERS);
  }, [location.pathname]);

  const setOrderAll = useCallback(
    (payload: OrderAllStateI) => {
      const { setOrderAll } = bindActions();
      setOrderAll(payload);
    },
    [bindActions]
  );

  return {
    isInOrders,
    setOrderAll,
  };
};

export default useOrderAll;
