import { Styles, GroupTypeBase } from 'react-select';
import { DropdownOptionType } from '@abc-intelligence/abc-ui';
import styled from 'styled-components';

export const ConsultCard = styled.div`
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 1.5rem;
  cursor: pointer;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: left;
  gap: 1rem;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryVeryLightBlue};
  }
`;

export const consultsSearchStyle:
  | Partial<
      Styles<DropdownOptionType, boolean, GroupTypeBase<DropdownOptionType>>
    >
  | undefined = {
  option: (provided) => {
    return {
      ...provided,
      padding: '0',
    };
  },
  menuList: (provided) => ({
    ...provided,
    maxHeight: '70vh',
  }),
  control: (provided) => ({
    ...provided,
    height: '3.2rem',
    minHeight: '3.2rem',
    width: '37.2rem',
    fontSize: '1.2rem',
    lineHeight: '2rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    top: '45%',
  }),
};
