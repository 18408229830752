import React from 'react';

import { Container, Label, Close } from './MHeaderTextClose.style';

interface MHeaderTextCloseProps {
  onClose: () => void;
  headerText: string;
}

const MHeaderTextClose: React.FC<MHeaderTextCloseProps> = ({
  onClose,
  headerText,
}) => {
  return (
    <Container>
      <Label>{`${headerText}`}</Label>
      <Close onClick={() => onClose()} />
    </Container>
  );
};

export default MHeaderTextClose;
