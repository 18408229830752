import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ConsultsURL } from 'base/api/urls';
import useApi from '../useApi/useApi';
import useStore from '../useStore/useStore';
import { getCleanQuery, getOptions } from 'utils';

const useConsultList = () => {
  const { t } = useTranslation();
  const { sendRequest } = useApi();
  const { bindActions } = useStore();
  const history = useHistory();

  const addNewConsult = useCallback(
    (consultId: number) => {
      const { setActiveConsultId } = bindActions();
      setActiveConsultId({ id: consultId });
      history.push(`/consult/${consultId}/dashboard`);
    },
    [bindActions, history]
  );

  const queryConsults = useCallback(
    async (query?: string) => {
      try {
        const searchQuery = query ? getCleanQuery(query) : '';
        const afterTime = Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 30;
        const beforeTime = Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 2;
        const url = searchQuery
          ? `${ConsultsURL}/?searchQuery=${searchQuery}&limit=100`
          : `${ConsultsURL}/?afterTime=${afterTime}&beforeTime=${beforeTime}&limit=100`;

        const data = await sendRequest({ url, method: 'GET' });
        return getOptions(data.consults, 'consults');
      } catch (err) {
        const { setError } = bindActions();
        setError(t('error.generalError'));
      }
      return [];
    },
    [bindActions, sendRequest, t]
  );

  return {
    queryConsults,
    addNewConsult,
  };
};

export default useConsultList;
