import styled from 'styled-components';

export default styled.textarea`
  display: block;
  border-radius: 2px;
  padding: 0.8rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.primaryDarkGray};

  &:focus,
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primaryBlue};
  }

  &:hover {
    border: 1px solid ${(p) => p.theme.colors.primaryBlue};
  }

  &,
  &:focus,
  &:active {
    outline: none;
  }
`;
