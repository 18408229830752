import { ConsultStateI, AppointmentStatusT } from 'base/types';
import { envConfig } from 'base/config';

import { DashboardPages } from 'consts';

export const MedicationsOptionsURL = '/products/genericMedications';
export const ConsultsURL = '/consults';

export const FrontPageConsultsURL = '/consults/frontpage/consults';
export const GetFrontPageDropdownsOptionsURL = '/consults/frontpage/options';
export const FrontPageDropdownsValuesURL =
  '/users/options/decisioning/frontpage/dropdowns';
export const GetFrontPageAppointmentsURL = '/consults/frontpage/appointments';
export const SetPinURL = '/consults/frontpage/consults/pin';

export const AnimalsURL = '/animals';

export const getUpdateConsultStatusURL = (
  consult_id: number,
  status: AppointmentStatusT
) => `/consults/${consult_id}/updateAppointmentStatus/${status}`;

export const getDefaultJsonPETemplatesURL = (clinic_id: number) =>
  `/clinics/${clinic_id}/physical-exam/templates-default`;

export const getTemplateDropdownOptionsURL = (clinic_id: number) =>
  `/clinics/${clinic_id}/physical-exam/templates`;

export const getJsonTemplateURL = (clinic_id: number, template_id: number) =>
  `/clinics/${clinic_id}/physical-exam/templates/${template_id}`;

export const getProblemListDropdownURL = (
  clinic_id: number,
  animalSpecies: string,
  searchQuery: string
) => `/clinics/${clinic_id}/problem-list/${searchQuery}/${animalSpecies}`;

export const getEstimateTemplatesURL = (
  consultId: ConsultStateI['consult_id']
) => `/consults/${consultId}/estimate/templates`;
export const getSearchModalitiesURL = (
  clinic_id: number,
  searchQuery: string
) => `/clinics/${clinic_id}/modalities/${searchQuery}`;
export const getEstimateEmailURL = (consultId: ConsultStateI['consult_id']) =>
  `/consults/${consultId}/estimate/email`;

export const getConsentEmailURL = (consultId: ConsultStateI['consult_id']) =>
  `/consults/${consultId}/consent-form/email`;
export const getConsentPrintURL = (consultId: ConsultStateI['consult_id']) =>
  `/consults/${consultId}/consent-form/print`;

export const getOrdersDropdownsURL = (consultId: ConsultStateI['consult_id']) =>
  `/consults/${consultId}/orders/dropdowns`;

export const getPostOrdersUrl = (consultId: ConsultStateI['consult_id']) =>
  `/consults/${consultId}/orders`;

export const getFinalizeOrderUrl = (consultId: ConsultStateI['consult_id']) =>
  `/consults/${consultId}/orders/vetconnect/finalize`;

export const getBlackwellUrl = (
  clinic_id: number,
  rulOutName: string,
  animalSpecies: string
) =>
  `/clinics/${clinic_id}/blackwell/${rulOutName}/${animalSpecies.toLowerCase()}`;

export const getProtocolsUrl = (
  clinic_id: number,
  rulOutName: string,
  animalSpecies: string
) =>
  `${
    envConfig.BASE_EMR
  }/clinics/${clinic_id}/protocols/${rulOutName}/${animalSpecies.toLowerCase()}`;

export const getChangeLogURL = (
  consultId: ConsultStateI['consult_id'],
  clinicId: number,
  limit = 25
) =>
  `/logs/changelogs?clinic_id=${clinicId}&consult_id=${consultId}&limit=${limit}`;

export const getPageBaseDataURL = (
  consultId: ConsultStateI['consult_id'],
  pageSegment: DashboardPages
) => `${ConsultsURL}/${consultId}/${pageSegment}`;

export const LOG_OUT_URL = '/auth/logout';
export const GET_USER_URL = '/user/current';
