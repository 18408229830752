import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE = false;

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: INITIAL_STATE,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => payload,
  },
});

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
