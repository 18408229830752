import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface InputErrorProps {
  children: React.ReactNode;
  error: string;
}

const InputErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ErrorMessage = styled.div`
  display: inline;
  line-height: 1.5rem;
  margin: 0.5rem 0 0;
  color: ${(p) => p.theme.colors.secondaryRed};
`;

const InputWithErrorCheck: React.FC<InputErrorProps> = ({
  error,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <InputErrorWrapper>
      {children}
      {error && <ErrorMessage>{t(`validationError.${error}`)}</ErrorMessage>}
    </InputErrorWrapper>
  );
};

export default InputWithErrorCheck;
