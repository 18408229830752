import { ConsultStateI } from 'base/types';

export const getNextActiveConsultId = (
  consults: ConsultStateI[],
  activeId: ConsultStateI['consult_id'],
  removedId: ConsultStateI['consult_id'],
  removedConsultIndex: number
): ConsultStateI['consult_id'] => {
  if (activeId !== removedId) return activeId;

  return consults.length === 0
    ? undefined
    : removedConsultIndex === 0
    ? consults[1].consult_id
    : consults[removedConsultIndex - 1].consult_id;
};
