import { APP_KEYS } from '@abc-intelligence/abc-ui';

import { envConfig } from 'base/config';

export const getInTwoDecimal = (num?: number) =>
  num ? Math.round((num + Number.EPSILON) * 100) / 100 : 0;

export const isLiteralObject = (o: any) => o?.constructor === Object;

export const doesQueryExist = (items: string[], query: string) => {
  return query
    ? items.some((it) => it && it.length === query.length && it === query)
    : false;
};

export const navigateToSignIn = () => {
  window.location.href = `${envConfig.SIGN_IN_URL}/?appredirect=${APP_KEYS.PET_GENUS}`;
};

export const navigateTo403 = () => {
  window.location.href = `${envConfig.PET_GENUS_URL}/403`;
};
