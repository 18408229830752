import { useReducer, useCallback } from 'react';
import { AxiosRequestConfig, AxiosError } from 'axios';

import { axiosRequest } from 'base/api';
// import useAbortController from '../useAbortController/useAbortController';
import useStore from 'hooks/useStore/useStore';

interface ApiStateI {
  status: 'idle' | 'pending' | 'resolved' | 'rejected';
  isLoading: boolean;
  data?: { [key: string]: any } | null;
  error?: any;
  requestIdentifier?: string;
}

interface ApiActionI {
  type: 'SEND' | 'SUCCESS' | 'ERROR' | 'CLEAR';
  payload: {
    requestIdentifier?: string;
    responseData?: { [key: string]: any };
    error?: any;
  };
}

const initialState: ApiStateI = {
  status: 'idle',
  isLoading: false,
  error: null,
  data: null,
  requestIdentifier: '',
};

const requestStateReducer = (
  currentState: ApiStateI,
  action: ApiActionI
): ApiStateI => {
  const { requestIdentifier, responseData, error } = action.payload;
  switch (action.type) {
    case 'SEND':
      return {
        status: 'pending',
        isLoading: true,
        error: null,
        data: null,
        requestIdentifier: requestIdentifier,
      };
    case 'SUCCESS':
      return {
        ...currentState,
        status: 'resolved',
        isLoading: false,
        data: responseData,
      };
    case 'ERROR':
      return { ...currentState, status: 'rejected', error, isLoading: false };
    case 'CLEAR':
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const useApi = () => {
  // const abortController = useAbortController();
  const [requestState, dispatchRequest] = useReducer(
    requestStateReducer,
    initialState
  );
  const { bindActions } = useStore();

  const clear = useCallback(
    () => dispatchRequest({ type: 'CLEAR', payload: {} }),
    []
  );

  // Fix the issue and uncomment ***

  // const setAbortSignalToRequest = useCallback(
  //   (requests: AxiosRequestConfig[] | AxiosRequestConfig) => {
  //     if (Array.isArray(requests)) {
  //       requests.forEach((request) => {
  //         request.signal = abortController?.signal;
  //       });
  //     } else {
  //       requests.signal = abortController?.signal;
  //     }
  //   },
  //   [abortController?.signal]
  // );

  const sendRequest = useCallback(
    async (requests: AxiosRequestConfig[] | AxiosRequestConfig) => {
      // setAbortSignalToRequest(requests);
      try {
        const result = await axiosRequest(requests);
        return result;
      } catch (error) {
        const { setLoading } = bindActions();
        setLoading(false);
        throw new Error((error as AxiosError).message);
      }
    },
    [bindActions]
  );

  const sendRequestWithStatus = useCallback(
    async (
      requests: AxiosRequestConfig[] | AxiosRequestConfig,
      requestIdentifier?: string
    ) => {
      dispatchRequest({ type: 'SEND', payload: { requestIdentifier } });

      try {
        const responseData = await sendRequest(requests);
        dispatchRequest({
          type: 'SUCCESS',
          payload: {
            responseData,
          },
        });
      } catch (error) {
        const { setLoading } = bindActions();
        setLoading(false);
        dispatchRequest({ type: 'ERROR', payload: { error } });
      }
    },
    [sendRequest, bindActions]
  );

  return {
    requestStatus: requestState.status,
    data: requestState.data,
    error: requestState.error,
    requestIdentifier: requestState.requestIdentifier,
    isLoading: requestState.isLoading,
    sendRequest,
    sendRequestWithStatus,
    clear,
  };
};

export default useApi;
