import { ConsultShortI, DropdownOptions, DropdownValues } from 'base/types';

export const sortByArrivalTime = (list: ConsultShortI[]): ConsultShortI[] => {
  return list.sort((c1, c2) => {
    if (!c1.arrival_time || !c2.arrival_time) return 0;
    return c1.arrival_time < c2.arrival_time ? -1 : 1;
  });
};

export const sortWaitingList = (list: ConsultShortI[]): ConsultShortI[] => {
  const statusObj = list.reduce(
    (resultObj, consult) => {
      switch (consult.triage_status) {
        case 'STAT':
          resultObj.stat.push(consult);
          break;
        case 'Serious':
          resultObj.serious.push(consult);
          break;
        case 'Stable':
          resultObj.stable.push(consult);
          break;
        default:
          resultObj.other.push(consult);
          break;
      }
      return resultObj;
    },
    {
      stat: [],
      serious: [],
      stable: [],
      other: [],
    } as {
      stat: ConsultShortI[];
      serious: ConsultShortI[];
      stable: ConsultShortI[];
      other: ConsultShortI[];
    }
  );

  return [
    ...sortByArrivalTime(statusObj.stat),
    ...sortByArrivalTime(statusObj.serious),
    ...sortByArrivalTime(statusObj.stable),
    ...sortByArrivalTime(statusObj.other),
  ];
};

export const getFrontPageDropdownValues = <T extends keyof DropdownValues>(
  selectedValues: DropdownValues[T],
  options: DropdownOptions[T]
) => {
  //@ts-ignore
  return options.filter((op) => selectedValues.includes(op.value));
};
