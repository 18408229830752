import React, { ReactNode, useState, useEffect } from 'react';

import { TooltipWrapper, TooltipTip } from './Tooltip.style';

export type Direction = 'top' | 'right' | 'bottom' | 'left';
export type TooltipType = 'primary' | 'danger' | 'warning';

export interface BaseTooltipProps {
  direction?: Direction;
  className?: string;
  disable?: boolean;
  type?: TooltipType;
  textFontSize?: string;
  maxWidth?: string;
}
interface TooltipProps extends BaseTooltipProps {
  delay?: number;
  message?: string;
  children: ReactNode;
  controlledMessage?: string;
  isControlled?: boolean;
  showContentDuration?: number;
}

const Tooltip: React.FC<TooltipProps> = ({
  direction = 'top',
  delay = 400,
  message,
  children,
  className,
  disable,
  controlledMessage,
  isControlled,
  showContentDuration = 2000,
  type = 'primary',
  textFontSize,
  maxWidth,
}) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);
  const [localControlledContent, setLocalControlledContent] = useState('');

  useEffect(() => {
    if (controlledMessage) {
      setLocalControlledContent(controlledMessage);
      setTimeout(() => {
        setLocalControlledContent('');
      }, showContentDuration);
    }
  }, [controlledMessage, showContentDuration]);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  if (!message) return <>{children}</>;
  return (
    <TooltipWrapper
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onMouseDown={hideTip}
      className={className}
    >
      {children}
      {isControlled && !disable && localControlledContent && (
        <TooltipTip
          direction={direction}
          type={type}
          textFontSize={textFontSize}
          maxWidth={maxWidth}
        >
          {localControlledContent}
        </TooltipTip>
      )}
      {!isControlled && !disable && active && (
        <TooltipTip
          direction={direction}
          type={type}
          textFontSize={textFontSize}
          maxWidth={maxWidth}
        >
          {message}
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
