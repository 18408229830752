import styled, { css, DefaultTheme } from 'styled-components';

export type InputType = 'primary' | 'secondary';

const getInputs = (theme: DefaultTheme, inputType?: InputType) => () => {
  switch (inputType) {
    case 'secondary':
      return css`
        border: 0.1rem solid ${theme.colors.primaryBlack};
        background: ${theme.colors.primaryGray};

        &:focus,
        &:active {
          box-shadow: 0 0 0 1px ${theme.colors.primaryBlack};
        }
      `;
    default:
      return css`
        border: 0.1rem solid ${theme.colors.primaryDarkGray};

        &:focus,
        &:active {
          box-shadow: 0 0 0 1px ${theme.colors.primaryBlue};
        }
      `;
  }
};

export default styled.input<{ inputType?: InputType }>`
  display: block;
  border-radius: 2px;
  padding: 0.8rem;

  ${({ inputType, theme }) => getInputs(theme, inputType)}

  &:hover {
    border: 1px solid ${(p) => p.theme.colors.primaryBlue};
  }

  &,
  &:focus,
  &:active {
    outline: none;
  }
`;
