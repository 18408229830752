import dayjs, { Dayjs } from 'dayjs';

import { ShiftsT } from 'base/types/api/frontPage';

export const getDateFromSec = (date: number) =>
  dayjs.unix(date).format('YYYY-MM-DD');

export const getDateAndTimeFromSec = (date: number) =>
  dayjs.unix(date).format('YYYY-MM-DD HH:mm:ss');

export const getTime = (date: number) => dayjs.unix(date).format('h:mm a');
export const getDaydddD = (date: number) => dayjs.unix(date).format('ddd D');
export const getCurrentTime = () => dayjs().format('h:mm a');

const getShiftText = (minMaxArray: Dayjs[]): string => {
  const min = minMaxArray[0]?.format('ddd D MMM');
  const max = minMaxArray[minMaxArray.length - 1]?.format('ddd D MMM');
  if (!max || min === max) return min;
  return `${min} - ${max}`;
};

export const getShiftPeriod = (shifts: ShiftsT[]): string => {
  let minMaxArray: Dayjs[] = [];
  if (!shifts.length) {
    minMaxArray.push(dayjs());
  }
  shifts.forEach((shift) => {
    switch (shift) {
      case 'Today':
        minMaxArray.push(dayjs());
        break;
      case 'Yesterday':
        minMaxArray.push(dayjs().subtract(24, 'hours').startOf('day'));
        break;
      case 'Previous and Next 12 hrs':
        minMaxArray.push(dayjs().subtract(12, 'hours').startOf('day'));
        minMaxArray.push(dayjs().add(12, 'hours').startOf('day'));
        break;
      case 'Previous and Next 24 hrs':
        minMaxArray.push(dayjs().subtract(24, 'hours').startOf('day'));
        minMaxArray.push(dayjs().add(24, 'hours').startOf('day'));
        break;
      default:
        break;
    }
  });
  minMaxArray = minMaxArray.sort();

  return getShiftText(minMaxArray);
};
