import React from 'react';

import { sizes } from 'base/styles/variables';
import { useStore } from 'hooks';
import Toast from '../Toast.style';

interface ErrorToastProps {}

const ErrorToast: React.FC<ErrorToastProps> = () => {
  const {
    state: { error },
    bindActions,
  } = useStore();
  const { removeError } = bindActions();

  return (
    <Toast
      headerHeight={sizes.header_height}
      message={error}
      onClose={removeError}
      toastType="error"
    />
  );
};

export default ErrorToast;
