import cloneDeep from 'lodash.clonedeep';

import { AnimalI, HistoryI, PhysicalExamI } from 'base/types';
import { isQuestionVisible } from 'utils';

const cleanMedicationsForSave = (history: HistoryI) => {
  history.admissions.admission.responses.medication_list = (
    history.admissions?.admission?.responses?.medication_list || []
  ).filter((m) => !!m.name);

  return history;
};

const getVisibleQuestionsId = (history: HistoryI, animal: AnimalI) => {
  const visibleQuestionsId = history.admissions.questions
    .filter((q) =>
      isQuestionVisible(q, {
        ...history.admissions.admission.responses,
        animal: { ...animal },
      })
    )
    .map((vq) => vq.id);

  history.admissions.admission.visible_questions_ids = visibleQuestionsId;
  return history;
};

export const saveReadyHistory = (history: HistoryI, animal: AnimalI) => {
  let newHistory = cloneDeep(history);

  newHistory = cleanMedicationsForSave(newHistory);
  newHistory = getVisibleQuestionsId(newHistory, animal);

  return newHistory;
};

export const saveReadyPhysicalExam = (physicalExam: PhysicalExamI) => {
  let newPhysicalExam = cloneDeep(physicalExam);

  if (newPhysicalExam.templates.length) {
    newPhysicalExam.templates = newPhysicalExam.templates.map((ph, i) => ({
      ...ph,
      order: i,
    }));
  }

  return newPhysicalExam;
};
