import { useStore } from 'hooks';

const useGetActiveConsult = () => {
  const {
    state: {
      consult: { activeConsultIndex, openConsults },
    },
  } = useStore();

  return openConsults[activeConsultIndex];
};

export default useGetActiveConsult;
