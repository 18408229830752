import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from 'hooks';
import { ConsultStateI } from 'base/types';
import {
  isInFrontPage,
  isItDashboardPage,
  isItDocumentationPage,
  getLastSegment,
} from 'utils';
import { PlusIcon, DeleteIcon } from 'base/assets/react-icons-lib';
import { TabsContainer, Tab, AddTab } from './Tabs.style';
import { SaveWarningModal, CustomTooltip } from 'components';

type ClickActionsT = 'switchTab' | 'deleteConsult' | 'addConsult' | '';
interface TabsProps {
  abcLogoClicked: boolean;
}

const Tabs: React.FC<TabsProps> = ({ abcLogoClicked }) => {
  const [showSavePopup, setShowSavePopup] = useState(false);
  const idToRemoveRef = useRef<ConsultStateI['consult_id']>(0);
  const idToSwitchRef =
    useRef<ConsultStateI['consult_id'] | 'navigateToFrontPage'>(0);

  const actionRef = useRef<{
    idToAction: ConsultStateI['consult_id'];
    actionType: ClickActionsT;
  }>({ idToAction: -1, actionType: '' });

  const location = useLocation();
  const currentPageName = getLastSegment(location.pathname);
  const history = useHistory();
  const { t } = useTranslation();

  const {
    state: {
      consult: { openConsults, activeConsultId, activeConsultIndex },
    },
    bindActions,
  } = useStore();

  const resetLocalData = useCallback(() => {
    idToSwitchRef.current = 0;
    idToRemoveRef.current = 0;
    actionRef.current = {
      idToAction: -1,
      actionType: '',
    };
    setShowSavePopup(false);
  }, []);

  useEffect(() => {
    if (actionRef.current.idToAction !== -1) {
      const { setActiveConsultId } = bindActions();
      if (!isInFrontPage(location.pathname)) {
        if (activeConsultId !== undefined) {
          history.push(
            `/consult/${activeConsultId}/dashboard/${currentPageName}`
          );
        }
      } else {
        setActiveConsultId({ id: undefined });
      }
      resetLocalData();
    }
  }, [
    activeConsultId,
    history,
    currentPageName,
    bindActions,
    resetLocalData,
    location.pathname,
  ]);

  const deleteConsult = useCallback(() => {
    const { removeConsult } = bindActions();
    removeConsult({ id: actionRef.current.idToAction });
    if (!isInFrontPage(location.pathname)) {
      if (
        openConsults.length === 1 &&
        actionRef.current.idToAction === activeConsultId
      ) {
        history.push('/home');
      }
    }
  }, [bindActions, activeConsultId, openConsults, location.pathname, history]);

  const switchTabHandler = useCallback(() => {
    const { setActiveConsultId } = bindActions();
    const { idToAction } = actionRef.current;
    setActiveConsultId({ id: idToAction });

    const pathname = location.pathname;
    if (isInFrontPage(pathname)) {
      history.push(`/consult/${idToAction}/dashboard`);
    } else if (isItDashboardPage(pathname)) {
      history.push(`/consult/${idToAction}/dashboard/${currentPageName}`);
    }
    resetLocalData();
  }, [
    bindActions,
    currentPageName,
    history,
    resetLocalData,
    location.pathname,
  ]);

  const actionHandler = useCallback(() => {
    const { actionType } = actionRef.current;
    switch (actionType) {
      case 'addConsult':
        history.push('/home');
        resetLocalData();
        break;
      case 'deleteConsult':
        deleteConsult();
        break;
      case 'switchTab':
        switchTabHandler();
        break;
      default:
        break;
    }
  }, [resetLocalData, deleteConsult, switchTabHandler, history]);

  const onClickHandler = useCallback(
    (
      actionType: ClickActionsT,
      e?: React.MouseEvent,
      idToAction?: ConsultStateI['consult_id']
    ) => {
      e?.stopPropagation();

      if (idToAction === activeConsultId && actionType === 'addConsult') return;

      actionRef.current = {
        idToAction: idToAction || -1,
        actionType,
      };
      if (
        activeConsultIndex !== -1 &&
        openConsults[activeConsultIndex].hasChanged
      ) {
        setShowSavePopup(true);
      } else {
        actionHandler();
      }
    },
    [actionHandler, activeConsultId, activeConsultIndex, openConsults]
  );

  useEffect(() => {
    if (abcLogoClicked) {
      onClickHandler('addConsult');
    }
  }, [abcLogoClicked, onClickHandler]);

  const renderTabs = () => {
    return openConsults.map((consult, index) => (
      <Tab
        active={consult.consult_id === activeConsultId}
        onClick={(e) => onClickHandler('switchTab', e, consult.consult_id)}
        key={consult.consult_id}
      >
        <p>{openConsults[index].animal_name}</p>
        <p>{consult.consult_id}</p>
        <DeleteIcon
          onClick={(e) =>
            onClickHandler('deleteConsult', e, consult.consult_id)
          }
        />
      </Tab>
    ));
  };

  const noClickHandler = () => {
    idToRemoveRef.current = 0;
    setShowSavePopup(false);
  };

  if (isItDocumentationPage(location.pathname)) return null;

  return (
    <TabsContainer>
      <SaveWarningModal
        onContinue={actionHandler}
        onCancel={noClickHandler}
        show={showSavePopup}
      />
      {renderTabs()}

      {!isInFrontPage(location.pathname) && (
        <>
          <AddTab
            onClick={(e) => onClickHandler('addConsult', e)}
            data-tip
            data-for="tabs-tooltip"
          >
            <PlusIcon />
            <CustomTooltip
              id={`tabs-tooltip`}
              direction="top"
              message={t(`tabs.addConsult`) as string}
              className="opaque"
            ></CustomTooltip>
          </AddTab>
        </>
      )}
    </TabsContainer>
  );
};

export default Tabs;
