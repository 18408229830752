import { Styles, GroupTypeBase } from 'react-select';
import { DropdownOptionType } from '@abc-intelligence/abc-ui';

export const asyncDropdownStyle:
  | Partial<
      Styles<DropdownOptionType, boolean, GroupTypeBase<DropdownOptionType>>
    >
  | undefined = {
  option: (provided, { data }) => {
    return {
      ...provided,
      fontWeight: data.isDefault ? 'bold' : 'inherit',
    };
  },
};
