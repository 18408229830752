import styled from 'styled-components';
import {
  BsFillPersonFill as PersonIcon,
  BsPersonCircle as PeopleIcon,
  BsArrowLeftRight as LogIcon,
  BsTrashFill,
  BsQuestionCircle as QuestionCircleIcon,
} from 'react-icons/bs';
import {
  AiOutlineFileAdd as FileAddIcon,
  AiOutlineSecurityScan as ExamIcon,
  AiOutlineDollar as EstimateIcon,
  AiOutlineShoppingCart as OrderIcon,
  AiOutlineExclamationCircle as InfoIcon,
  AiFillCaretDown as CaretDownFillIcon,
  AiOutlineDollarCircle as DollarIcon,
  AiFillPrinter as PrinterIcon,
  AiOutlineCheck as CheckIcon,
  AiFillWarning as WarningIcon,
} from 'react-icons/ai';
import { HiOutlineKey as KeyIcon } from 'react-icons/hi';
import {
  MdLanguage as LangIcon,
  MdCancel as CancelIcon,
  MdFormatAlignLeft as ConsentFormIcon,
} from 'react-icons/md';
import {
  RiLogoutBoxRLine as LogoutIcon,
  RiHistoryFill as HistoryIcon,
  RiHeartPulseLine as TriageIcon,
  RiPushpin2Line as PinIcon,
  RiPushpin2Fill as PinFillIcon,
} from 'react-icons/ri';
import { TiPlusOutline as PlusIcon } from 'react-icons/ti';
import {
  FiAlertTriangle as ProblemIcon,
  FiCheckSquare as RuleOutsIcon,
} from 'react-icons/fi';
import { SiGumroad as PlanIcon } from 'react-icons/si';
import {
  BiLeftArrowCircle as ArrowLeftIcon,
  BiRightArrowCircle as ArrowRightIcon,
  BiListOl as NumberListIcon,
  BiMessageCheck as MessageCheckIcon,
  BiCheckCircle as CircleCheckIcon,
  BiCommentDots as CommentDotsIcon,
  BiLinkExternal as ExternalLinkIcon,
} from 'react-icons/bi';

import {
  HiOutlineX as CloseIcon,
  HiOutlineChevronUp as ChevronUpIcon,
  HiOutlineChevronDown as ChevronDownIcon,
  HiOutlineClipboard as ClipboardIcon,
  HiOutlineClipboardCheck as ClipboardCheckIcon,
} from 'react-icons/hi';

import { IoMdExit as DischargeIcon } from 'react-icons/io';
import { IoRefreshOutline as RefreshIcon } from 'react-icons/io5';

const DeleteIcon = styled(CancelIcon)`
  ${({ theme }) => theme.mixin.DeleteItemCSS}
`;

const TrashIcon = styled(BsTrashFill)`
  ${({ theme }) => theme.mixin.DeleteItemCSS}
`;

export {
  PersonIcon,
  FileAddIcon,
  KeyIcon,
  LangIcon,
  LogoutIcon,
  PeopleIcon,
  CancelIcon,
  DeleteIcon,
  TrashIcon,
  PlusIcon,
  HistoryIcon,
  TriageIcon,
  ExamIcon,
  ProblemIcon,
  RuleOutsIcon,
  EstimateIcon,
  ConsentFormIcon,
  OrderIcon,
  PlanIcon,
  DischargeIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  LogIcon,
  InfoIcon,
  CaretDownFillIcon,
  ChevronDownIcon,
  CloseIcon,
  ChevronUpIcon,
  DollarIcon,
  PrinterIcon,
  CheckIcon,
  RefreshIcon,
  NumberListIcon,
  MessageCheckIcon,
  CircleCheckIcon,
  CommentDotsIcon,
  ClipboardIcon,
  ClipboardCheckIcon,
  PinIcon,
  PinFillIcon,
  ExternalLinkIcon,
  WarningIcon,
  QuestionCircleIcon,
};
