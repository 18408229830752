import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InputValidationStateI, InputValidationPayloadI } from 'base/types';

import { inputValidationHelper } from './utils';

const INITIAL_STATE = {};

export const inputValidationSlice = createSlice({
  name: 'inputValidation',
  initialState: INITIAL_STATE as InputValidationStateI,
  reducers: {
    setInputValidationError: (
      state,
      { payload }: PayloadAction<InputValidationPayloadI>
    ) => {
      inputValidationHelper(state, payload);
    },
    removeValidationError: (state, { payload }: PayloadAction<string>) => {
      delete state[payload];
    },
    removeAllInputValidationErrors: (state) => {
      state = {};
    },
  },
});

export const {
  setInputValidationError,
  removeValidationError,
  removeAllInputValidationErrors,
} = inputValidationSlice.actions;
export default inputValidationSlice.reducer;
