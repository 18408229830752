import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import {
  PageHeader,
  PHOptionsContainer,
  PHAppMenu,
  PetGenusWhiteLogo,
  DarkDropdown,
  DropdownOptionType,
  APP_KEYS,
} from '@abc-intelligence/abc-ui';

import { envConfig } from 'base/config';
import { Menu, ConsultSearchBar } from './components';
import { useStore, useGetActiveConsult, useApi } from 'hooks';
import { isItDashboardPage, isInFrontPage } from 'utils';
import { GET_USER_URL } from 'base/api';
import { APP_OPTIONS } from './consts';

import { PatientName, SiteDropdownWrapper } from './Navbar.style';
import { UserI } from 'base/types';

export interface NavbarProps {
  abcLogoClickHandler: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ abcLogoClickHandler }) => {
  const {
    state: { user, animal },
    bindActions,
  } = useStore();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const activeConsult = useGetActiveConsult();
  const { sendRequest } = useApi();

  const getUserAccessibleApps = () => {
    const userAppKeys = user?.user_apps?.map((app) => app.app_key);
    return APP_OPTIONS.filter((app) => userAppKeys?.includes(app.appName));
  };

  const displayHeaderCenter = () =>
    animal && activeConsult && isItDashboardPage(location.pathname) ? (
      <PatientName>
        {`${animal.name} | ${activeConsult.consult_id}`}
      </PatientName>
    ) : isInFrontPage(location.pathname) ? (
      <ConsultSearchBar />
    ) : (
      <div></div>
    );

  const getSitesOptions: DropdownOptionType[] = useMemo(() => {
    if (user) {
      const userSiteIds = user.user_apps_clinic_ids.filter((appSiteId) => {
        return (
          appSiteId.app_key === APP_KEYS.VET_GENUS ||
          appSiteId.app_key === APP_KEYS.PET_GENUS
        );
      });
      return userSiteIds.map((siteId) => ({
        label: siteId.clinic_name || '',
        value: siteId.clinic_id,
      }));
    }
    return [];
  }, [user]);

  const getSiteDropdownValue = () => {
    return getSitesOptions.filter(
      (app) => app.value === user?.user_default_clinic_id
    );
  };

  const setSelectedSite = async (siteId: number) => {
    const { setError, setUser } = bindActions();
    try {
      const response = await sendRequest({
        method: 'POST',
        baseURL: `${envConfig.BASE_API}${GET_USER_URL}/activesite`,
        data: {
          site_id: siteId,
        },
      });
      setUser(response as UserI);
      history.go(0); // *** add site id to react-query keys later
    } catch (err) {
      setError(t('error.generalError'));
    }
  };

  const isIt403Page = location.pathname === '/403';

  return (
    <PageHeader
      headerLeft={
        <PHOptionsContainer position="left">
          <PetGenusWhiteLogo
            cursor={
              isItDashboardPage(location.pathname) ? 'pointer' : undefined
            }
            onClick={abcLogoClickHandler}
          />
          {!isIt403Page && (
            <SiteDropdownWrapper>
              <DarkDropdown
                value={getSiteDropdownValue()}
                dynamicMenuWidth
                options={getSitesOptions}
                oneSelectHandler={(option) => setSelectedSite(option.value)}
                hideBorder
                isSearchable={false}
                isDisabled={isItDashboardPage(location.pathname)}
              />
            </SiteDropdownWrapper>
          )}
        </PHOptionsContainer>
      }
      headerCenter={displayHeaderCenter()}
      headerRight={
        <PHOptionsContainer position="right">
          <PHAppMenu
            apps={getUserAccessibleApps()}
            menuHorizontalPlacement={'left'}
          />
          <Menu user={user} />
        </PHOptionsContainer>
      }
    />
  );
};

export default Navbar;
