import * as yup from 'yup';

type ValidationIdentifiersT = 'email' | 'phoneNumber';
type ValidationValueTypes = string | number | null | undefined;

const validateEmail = async (value: ValidationValueTypes) => {
  const emailSchema = yup.string().email().max(255).required();
  return await emailSchema.isValid(value);
};

const validatePhoneNumber = async (value: ValidationValueTypes) => {
  const phoneNumberSchema = yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    );
  return await phoneNumberSchema.isValid(value);
};

const getValidationFunction = (vIdentifier: ValidationIdentifiersT) => {
  switch (vIdentifier) {
    case 'email':
      return validateEmail;
    case 'phoneNumber':
      return validatePhoneNumber;
    default: {
      throw new Error(`Unhandled validation Identifier`);
    }
  }
};

export const yupValidate = async (
  vIdentifier: ValidationIdentifiersT,
  value: ValidationValueTypes
) => {
  const validationFunction = getValidationFunction(vIdentifier);
  let isValid = true;

  try {
    isValid = await validationFunction(value);
  } catch (err) {
    throw new Error(`Error in validation`);
  }

  return isValid;
};

export const notEmptyStrValidate = (value: string | null | undefined) =>
  !!value && value.length > 0;
