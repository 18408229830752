import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useApi } from 'hooks';
import { ConsultsURL } from 'base/api/urls';
import { DashboardPages } from 'consts';
import { AnimalI, HistoryI, PhysicalExamI, ConsultStateI } from 'base/types';
import { getLastSegment } from 'utils';
import { saveReadyHistory, saveReadyPhysicalExam } from './utils';
import { useGetActiveConsult, useStore, usePageValidation } from 'hooks';

const useSaveConsult = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const activeConsult = useGetActiveConsult();
  const {
    state: { animal },
    bindActions,
  } = useStore();
  const { sendRequestWithStatus, requestStatus, clear, data } = useApi();
  const { isPageStateValid } = usePageValidation();

  const savePage = useCallback(() => {
    if (!isPageStateValid()) return;

    const pageName = getLastSegment(location.pathname) as DashboardPages;
    let body: object;
    const {
      history,
      triage_and_vitals,
      physical_exam,
      rule_outs,
      problem_list,
      estimate,
      consent_form,
      orders,
      plan,
    } = activeConsult as ConsultStateI;

    switch (pageName) {
      case 'history':
        const historyData = saveReadyHistory(
          history as HistoryI,
          animal as AnimalI
        );
        body = { history: historyData };
        break;
      case 'triage-and-vitals':
        body = { triage_and_vitals: triage_and_vitals || [] };
        break;
      case 'physical-exam':
        const physicalExamData = saveReadyPhysicalExam(
          physical_exam as PhysicalExamI
        );
        body = { physical_exam: physicalExamData };
        break;
      case 'rule-outs':
        body = { rule_outs };
        break;
      case 'problem-list':
        body = { problem_list };
        break;
      case 'estimate':
        body = { estimate };
        break;
      case 'consent-form':
        body = { consent_form } || {};
        break;
      case 'orders':
        body = { orders } || {};
        break;
      case 'plan':
        body = { plan };
        break;
      default:
        throw new Error(`Unhandled action type: ${pageName}`);
    }
    const url = `${ConsultsURL}/${activeConsult.consult_id}/${pageName}`;
    sendRequestWithStatus({
      url,
      method: 'POST',
      data: body,
    });
  }, [
    activeConsult,
    isPageStateValid,
    location.pathname,
    animal,
    sendRequestWithStatus,
  ]);

  useEffect(() => {
    const { setError, setSuccess, setHasChanged } = bindActions();
    switch (requestStatus) {
      case 'resolved':
        if (data?.success) {
          setSuccess(t('general.saveSuccess'));
          setHasChanged({ id: activeConsult?.consult_id, hasChanged: false });
        } else {
          setError(t('error.generalError'));
        }
        clear();
        break;
      case 'rejected':
        setError(t('error.generalError'));
        clear();
        break;
      default:
        break;
    }
  }, [requestStatus, clear, bindActions, t, activeConsult?.consult_id, data]);

  return {
    savePage,
    saveStatus: requestStatus,
    clear,
  };
};

export default useSaveConsult;
