import { AxiosError } from 'axios';

import { axiosRequest } from 'base/api';
import {
  FrontPageConsultsURL,
  GetFrontPageAppointmentsURL,
  GetFrontPageDropdownsOptionsURL,
  FrontPageDropdownsValuesURL,
  SetPinURL,
} from 'base/api/urls';
import {
  DropdownValues,
  ConsultShortI,
  DropdownOptions,
  AppointmentI,
} from 'base/types';
import { sortWaitingList } from '../utils';

const getDropdownQuery = (values: string[]) => values.join(',');

export const fetchConsultsAndAppointments = async (
  dropdownValues: DropdownValues
): Promise<{
  hospitalConsultList: ConsultShortI[];
  waitingRoomConsultList: ConsultShortI[];
}> => {
  try {
    const { departments, case_owners } = dropdownValues;
    const consultUrl = `${FrontPageConsultsURL}?departments=${getDropdownQuery(
      departments
    )}&case_owners=${getDropdownQuery(
      case_owners
    )}&status=In Waiting Room,In Hospital`;

    const result = await axiosRequest({
      url: consultUrl,
      method: 'GET',
    });

    const hospitalConsultList =
      (result.consults as ConsultShortI[]).filter(
        (consult: ConsultShortI) => consult.status === 'In Hospital'
      ) || [];

    const waitingRoomConsultList =
      sortWaitingList(
        (result.consults as ConsultShortI[]).filter(
          (consult: ConsultShortI) => consult.status === 'In Waiting Room'
        )
      ) || [];

    return {
      hospitalConsultList,
      waitingRoomConsultList,
    };
  } catch (err) {
    throw new Error((err as AxiosError).message);
  }
};

export const fetchAppointments = async (
  dropdownValues: DropdownValues
): Promise<AppointmentI[]> => {
  try {
    const { departments, case_owners, shifts } = dropdownValues;
    const appointmentUrl = `${GetFrontPageAppointmentsURL}?departments=${getDropdownQuery(
      departments
    )}&case_owners=${getDropdownQuery(case_owners)}&shifts=${getDropdownQuery(
      shifts
    )}`;

    const result = await axiosRequest({
      url: appointmentUrl,
      method: 'GET',
    });

    return result.consults as AppointmentI[];
  } catch (err) {
    throw new Error((err as AxiosError).message);
  }
};

export const fetchDropdownData = async (): Promise<{
  dropdownOptions: DropdownOptions;
  dropdownValues: DropdownValues;
}> => {
  try {
    const result = await axiosRequest([
      {
        url: GetFrontPageDropdownsOptionsURL,
        method: 'GET',
      },
      {
        url: FrontPageDropdownsValuesURL,
        method: 'GET',
      },
    ]);
    return {
      dropdownOptions: result[0] as DropdownOptions,
      dropdownValues: result[1] as DropdownValues,
    };
  } catch (err) {
    throw new Error((err as AxiosError).message);
  }
};

export const updateDropdown = async (dropdownValues: DropdownValues) => {
  try {
    await axiosRequest({
      url: FrontPageDropdownsValuesURL,
      method: 'POST',
      data: dropdownValues,
    });
  } catch (err) {
    throw new Error((err as AxiosError).message);
  }
};

export const setPin = async (consult_id: ConsultShortI['consult_id']) => {
  try {
    await axiosRequest({
      url: SetPinURL,
      method: 'POST',
      data: { consult_id },
    });
  } catch (err) {
    throw new Error((err as AxiosError).message);
  }
};
