import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'hooks';

const usePageValidation = () => {
  const { t } = useTranslation();
  const {
    state: { inputValidation },
    bindActions,
  } = useStore();

  const isPageStateValid = useCallback(
    (obj?: object): boolean => {
      const validationObject = obj || inputValidation;
      for (let inputError of Object.values(validationObject)) {
        if (typeof inputError === 'object') {
          return isPageStateValid(inputError);
        } else {
          if (inputError) {
            const { setError } = bindActions();
            setError(t('error.inputError'));
            return false;
          }
        }
      }
      return true;
    },
    [inputValidation, bindActions, t]
  );

  return { isPageStateValid };
};

export default usePageValidation;
