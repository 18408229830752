import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const Tooltip = styled(ReactTooltip)`
  &.opaque {
    opacity: 1 !important;
  }
  &.mw-60 {
    max-width: 60rem;
  }
  &[class~='text-1.5'] {
    font-size: 1.5rem;
  }
`;

export { Tooltip };
