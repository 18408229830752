export enum DashboardPages {
  HISTORY = 'history',
  TRIAGE_AND_VITAL = 'triage-and-vitals',
  PHYSICAL_EXAM = 'physical-exam',
  PROBLEM_LIST = 'problem-list',
  RULE_OUTS = 'rule-outs',
  ESTIMATE = 'estimate',
  CONSENT_FORM = 'consent-form',
  ORDERS = 'orders',
  PLAN = 'plan',
  CHANGE_LOG = 'change-log',
  DISCHARGE_SUMMARY = 'discharge-summary',
}
