import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ButtonBoardStateI, OrderT, OrderAllStateI } from 'base/types';
import { consultSlice } from '../consult/consultReducer';
import { RootState } from 'base/store';

const INITIAL_STATE: ButtonBoardStateI = {
  orders: {
    orderAll: {
      isAllOrdered: false,
      comingRoute: '',
    },
    changedOrdersT: [],
  },
  estimate: {
    isEmailClicked: false,
  },
};

const buttonBoardSlice = createSlice({
  name: 'buttonBoard',
  initialState: INITIAL_STATE,
  reducers: {
    setOrderAll: (state, { payload }: PayloadAction<OrderAllStateI>) => {
      state.orders.orderAll = payload;
    },
    setChangedOrderTypes: (state, { payload }: PayloadAction<OrderT[]>) => {
      state.orders.changedOrdersT = payload;
    },
    addChangedOrderType: (state, { payload }: PayloadAction<OrderT>) => {
      if (!state.orders.changedOrdersT.find((cot) => cot === payload)) {
        state.orders.changedOrdersT.push(payload);
      }
    },
    deleteChangedOrderType: (state, { payload }: PayloadAction<OrderT>) => {
      const index = state.orders.changedOrdersT.findIndex(
        (cot) => cot === payload
      );
      state.orders.changedOrdersT.splice(index, 1);
    },
    emptyChangedOrderType: (state) => {
      state.orders.changedOrdersT = [];
    },
    estimateEmailClick: (state, { payload }: PayloadAction<boolean>) => {
      state.estimate.isEmailClicked = payload;
    },
  },
});

export const updateOrdersStatus = createAsyncThunk(
  'orders/updateOrdersStatus',
  (
    payload: {
      orderType: OrderT;
      nextOrPrevRout?: string;
    },
    thunkApi
  ) => {
    const { orderType, nextOrPrevRout } = payload;
    const { dispatch, getState } = thunkApi;
    const {
      consult: { activeConsultId },
      btnBoard,
    } = getState() as RootState;

    // set new value to hasChanged
    if (orderType === 'all') {
      dispatch(buttonBoardSlice.actions.emptyChangedOrderType());
      dispatch(
        consultSlice.actions.setHasChanged({
          id: activeConsultId,
          hasChanged: false,
        })
      );

      dispatch(
        buttonBoardSlice.actions.setOrderAll({
          isAllOrdered: false,
          comingRoute: nextOrPrevRout || btnBoard.orders.orderAll.comingRoute,
        })
      );
    } else {
      // order.changedOrdersT.length means there is only the current orderType in
      // changedOrdersT which will be deleted in the next line
      dispatch(
        consultSlice.actions.setHasChanged({
          id: activeConsultId,
          hasChanged: btnBoard.orders.changedOrdersT.length !== 1,
        })
      );
      dispatch(buttonBoardSlice.actions.deleteChangedOrderType(orderType));
    }
  }
);

export const {
  setOrderAll,
  setChangedOrderTypes,
  addChangedOrderType,
  deleteChangedOrderType,
  emptyChangedOrderType,
  estimateEmailClick,
} = buttonBoardSlice.actions;
export default buttonBoardSlice.reducer;
