import { getNextActiveConsultId } from 'utils';
import { DashboardPages } from 'consts';
import {
  UpdateConsultValueType,
  ConsultI,
  ConsultStateI,
  InitialConsultI,
  AppointmentStatusT,
} from 'base/types';

export const getConsultIndex = (
  consults: ConsultStateI[],
  id: ConsultStateI['consult_id']
) => consults.findIndex((c) => c.consult_id === id);

export const addConsultHelper = (
  consult: ConsultStateI,
  state: InitialConsultI
) => {
  if (!state.openConsults.find((c) => c.consult_id === consult.consult_id)) {
    consult.hasChanged = false;
    state.openConsults.push(consult);
    state.activeConsultId = consult.consult_id;
    state.activeConsultIndex = state.openConsults.length - 1;
  }
};

export const removeConsultHelper = (
  state: InitialConsultI,
  id: ConsultStateI['consult_id']
) => {
  const removedConsultIndex = getConsultIndex(state.openConsults, id);
  state.openConsults.splice(removedConsultIndex, 1);
  // Set New activeConsultId
  const newActiveId = getNextActiveConsultId(
    state.openConsults,
    state.activeConsultId,
    id,
    removedConsultIndex
  );

  state.activeConsultId = newActiveId;
  state.activeConsultIndex = getConsultIndex(state.openConsults, newActiveId);
};

export const hasChangedHelper = (
  state: InitialConsultI,
  id: ConsultStateI['consult_id'],
  hasChanged: ConsultStateI['hasChanged']
) => {
  const consultIndex = getConsultIndex(state.openConsults, id);
  if (consultIndex !== -1) {
    state.openConsults[consultIndex].hasChanged = hasChanged;
  }
};

export const updateConsultHandler = (
  state: InitialConsultI,
  dashboardPage: DashboardPages,
  value: UpdateConsultValueType,
  hasChanged?: boolean
) => {
  const { activeConsultIndex } = state;

  if (activeConsultIndex === -1)
    throw new Error('Can not find the active consult');

  const activeConsult = state.openConsults[activeConsultIndex];
  activeConsult.hasChanged = hasChanged !== undefined ? hasChanged : true;

  switch (dashboardPage) {
    case DashboardPages.HISTORY:
      activeConsult.history = value as ConsultI['history'];
      break;
    case DashboardPages.TRIAGE_AND_VITAL:
      activeConsult.triage_and_vitals = value as ConsultI['triage_and_vitals'];
      break;
    case DashboardPages.PHYSICAL_EXAM:
      activeConsult.physical_exam = value as ConsultI['physical_exam'];
      break;
    case DashboardPages.PROBLEM_LIST:
      activeConsult.problem_list = value as ConsultI['problem_list'];
      break;

    case DashboardPages.RULE_OUTS:
      activeConsult.rule_outs = value as ConsultI['rule_outs'];
      break;
    case DashboardPages.ESTIMATE:
      activeConsult.estimate = value as ConsultI['estimate'];
      break;

    case DashboardPages.CONSENT_FORM:
      activeConsult.consent_form = value as ConsultI['consent_form'];
      break;
    case DashboardPages.ORDERS:
      activeConsult.orders = value as ConsultI['orders'];
      break;
    case DashboardPages.PLAN:
      activeConsult.plan = value as ConsultI['plan'];
      break;

    default:
      throw new Error('The update consult type is not valid');
  }
};

export const setConsultHelper = (
  state: InitialConsultI,
  consult: ConsultStateI,
  index: number
) => {
  consult.hasChanged = !!consult.hasChanged;
  consult.status_has_Selected =
    state.openConsults[index]?.status_has_Selected || false;
  state.openConsults[index] = consult;
};

export const setConsultStatusHelper = (
  state: InitialConsultI,
  consultIndex: number,
  status: AppointmentStatusT
) => {
  const consult = state.openConsults[consultIndex];
  consult.status = status;
  consult.status_has_Selected = true;
};
