import { colors } from '@abc-intelligence/abc-ui';
import { devices, sizes } from '../variables';
import * as mixin from '../mixin';

export type ThemeType = typeof theme;

const theme = {
  devices,
  colors,
  sizes,
  mixin,
};

export default theme;
