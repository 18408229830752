import i18next from 'i18next';
import { DropdownOptionType } from '@abc-intelligence/abc-ui';

export type OptionsType =
  | 'default'
  | 'consults'
  | 'rule-outs'
  | 'estimate_template'
  | 'estimate_modality'
  | 'decLineReason'
  | 'orders'
  | 'physical-exam'
  | 'problemList_dropdown';

const makeLabel = (label: string) =>
  label.charAt(0).toUpperCase() + label.slice(1);

export const getOption = (item?: any) => {
  if (!item) return;

  return {
    label: makeLabel(item.toString()),
    value: item,
  };
};

export const getOptions = (
  items?: any[],
  type?: OptionsType
): DropdownOptionType[] => {
  if (!items) return [];
  switch (type) {
    case 'consults':
      return items
        .filter((item) => item.animal_name)
        .map((item) => {
          const label = item.animal_name.toString();
          return {
            label: makeLabel(label),
            value: item,
          };
        });
    case 'rule-outs':
    case 'orders':
    case 'estimate_template':
    case 'problemList_dropdown':
      return items
        .filter((item) => item.name)
        .map((item) => {
          const label = item.name.toString();
          return {
            label: makeLabel(label),
            value: item,
          };
        });
    case 'estimate_modality':
      return items
        .filter((item) => item.product_name)
        .map((item) => {
          const label = item.product_name.toString();
          return {
            label: makeLabel(label),
            value: item,
          };
        });
    case 'physical-exam':
      return items
        .filter((item) => item.title)
        .map((item) => {
          const label = item.title.toString();
          return {
            label: makeLabel(label),
            value: item.id,
          };
        });
    default:
      return items.map((item) => getOption(item) || { label: '', value: null });
  }
};

export const getBoldOptions = (
  options?: DropdownOptionType[]
): DropdownOptionType[] =>
  options?.map((option) => ({ ...option, isBold: true })) || [];

export const getUniqueOptions = (
  allOptions: DropdownOptionType[],
  selectedOptions: DropdownOptionType[]
): DropdownOptionType[] => {
  const finalOptions = [...allOptions].filter((o) => {
    return selectedOptions.findIndex((so) => so.label === o.label) === -1;
  });
  return finalOptions;
};

export const getTranslatedOptionValue = (
  option: { labelTCode: string; value: any },
  tKey: string
): DropdownOptionType => ({
  label: i18next.t(`${tKey}.${option.labelTCode}`),
  value: option.value,
});

export const getTranslatedOptions = (
  options: { labelTCode: string; value: any }[],
  tKey: string
): DropdownOptionType[] =>
  options.map((o) => getTranslatedOptionValue(o, tKey));
