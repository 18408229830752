import styled, { css } from 'styled-components';

import PairTitle from 'components/PairTitle/PairTitle';

export const Container = styled.div`
  border: 1px solid ${(p) => p.theme.colors.primaryBlack};
`;

export const Title = styled.div<{
  collapsed?: boolean;
  innerSection?: boolean;
}>`
  display: flex;
  justify-content: space-between;

  background: ${({ theme, innerSection }) =>
    innerSection ? 'transparent' : theme.colors.primaryGray};
  color: ${({ theme, innerSection }) =>
    innerSection ? theme.colors.primaryBlack : theme.colors.primaryBlue};
  font-size: ${({ innerSection }) => (innerSection ? '1.5rem' : '1.7rem')};
  text-transform: uppercase;
  border-bottom: ${({ theme, collapsed }) =>
    collapsed ? `1px solid ${theme.colors.primaryBlack}` : 'none'};
  padding: ${({ theme }) => theme.sizes.section_padding};
  font-weight: 500;
`;

export const TitleExtraContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: ${({ theme }) => theme.sizes.section_padding};
  width: max-content;
  align-items: center;

  & > svg {
    cursor: pointer;
    font-size: 1.5rem;
  }
`;

export const SectionPairTitleDefault = styled(PairTitle)`
  span:first-child {
    font-size: 1.6rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.main900};
  }
  span:last-child {
    font-size: 1.4rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.main500};
  }
`;

const getCollapseAnimation = (collapsible?: boolean, collapsed?: boolean) => {
  if (!collapsible) return css``;
  return css`
    max-height: ${collapsed ? '400rem' : 0};
    -webkit-transition: max-height 0.1s ease-in, padding 0.1s ease-in;
    -moz-transition: max-height 0.1s ease-in, padding 0.1s ease-in;
    transition: max-height 0.1s ease-in, padding 0.1s ease-in;
  `;
};

export const Content = styled.div<{
  collapsible?: boolean;
  collapsed?: boolean;
  contentHeight?: string;
  hiddenScrollbar?: boolean;
}>`
  padding: ${({ collapsed, theme }) =>
    collapsed ? `${theme.sizes.section_padding}` : 0};
  overflow-y: auto;
  height: ${(p) => p.contentHeight || '100%'};
  ${(p) => getCollapseAnimation(p.collapsible, p.collapsed)}
  ${({ hiddenScrollbar, theme }) => hiddenScrollbar && theme.mixin.noScrollBar}
`;
