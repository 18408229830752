import React, { ReactNode } from 'react';
import { colors } from '@abc-intelligence/abc-ui';

import { Tooltip } from './CustomTooltip.style';

export type TooltipType = 'primary' | 'danger' | 'warning';
export type Direction = 'top' | 'right' | 'bottom' | 'left';
export type effectType = 'float' | 'solid';

export interface ToolTipProps {
  id?: string;
  textColor?: string;
  className?: string;
  type?: TooltipType;
  message?: string;
  children?: ReactNode;
  direction?: Direction;
  maxWidth?: string;
  effect?: effectType;
  disable?: boolean;
  delayHide?: number;
  delayShow?: number;
}

const getBackgroundColor = (type: TooltipType | undefined) => {
  return type === 'danger'
    ? colors.secondaryRed
    : type === 'warning'
    ? colors.secondaryOrange
    : colors.secondaryBlue;
};

const CustomTooltip: React.FC<ToolTipProps> = ({
  id,
  textColor,
  type,
  message,
  direction,
  className,
  effect = 'solid',
  disable,
  delayHide,
  delayShow = 400,
}) => {
  return (
    <Tooltip
      id={id}
      place={direction}
      backgroundColor={getBackgroundColor(type)}
      className={className}
      textColor={textColor}
      effect={effect}
      disable={disable}
      delayHide={delayHide}
      delayShow={delayShow}
    >
      <span>{message}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
