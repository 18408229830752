import styled from 'styled-components';

const PairTitle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 0.5rem;
  align-items: center;
`;

export default PairTitle;
