import React from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncDropdown } from '@abc-intelligence/abc-ui';

import { getDateFromSec } from 'utils';
import { ConsultShortI } from 'base/types';
import { useConsultList } from 'hooks';
import { ConsultCard, consultsSearchStyle } from './ConsultSearchBar.style';

interface ConsultSearchBarProps {}

const ConsultSearchBar: React.FC<ConsultSearchBarProps> = () => {
  const { queryConsults, addNewConsult } = useConsultList();
  const { t } = useTranslation();
  const ConsultOption = ({
    value,
  }: {
    label: string;
    value: ConsultShortI;
  }) => (
    <ConsultCard onClick={() => addNewConsult(value.consult_id)}>
      <p>{value.consult_number}</p>
      <p>{value.animal_name}</p>
      <p>{value.customer_name}</p>
      <p>{getDateFromSec(value.consult_date)}</p>
    </ConsultCard>
  );

  return (
    <AsyncDropdown
      debounceLoadOptions={queryConsults}
      oneSelectHandler={({ value }) => addNewConsult(value.consult_id)}
      placeholder={t('frontPage.searchConsult')}
      formatOptionLabel={ConsultOption}
      styles={consultsSearchStyle}
    />
  );
};

export default ConsultSearchBar;
