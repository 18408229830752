import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE = '';

export const successSlice = createSlice({
  name: 'success',
  initialState: INITIAL_STATE,
  reducers: {
    setSuccess: (state, { payload }: PayloadAction<string>) => payload,
    removeSuccess: () => '',
  },
});

export const { setSuccess, removeSuccess } = successSlice.actions;
export default successSlice.reducer;
