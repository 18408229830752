import styled from 'styled-components';

import { KeyPairInfo } from 'components';
import { DeleteIcon } from 'base/assets/react-icons-lib';

const KeyPairDeleteIcon = styled(DeleteIcon)`
  margin-right: 0.5rem;
`;
const ChildrenContainer = styled.div`
  padding-bottom: 0.2rem;
`;

interface DeletableKeyPairProps {
  onDelete: () => void;
  children: React.ReactNode;
  className?: string;
}

const DeletableKeyPair: React.FC<DeletableKeyPairProps> = ({
  onDelete,
  children,
  className,
}) => {
  return (
    <KeyPairInfo notBoldKey className={className}>
      <KeyPairDeleteIcon onClick={onDelete} />
      <ChildrenContainer>{children}</ChildrenContainer>
    </KeyPairInfo>
  );
};

export default DeletableKeyPair;
