import styled, { DefaultTheme, css } from 'styled-components';

type KeyPairType = 'primary' | 'secondary';

interface KeyPairProps {
  type?: KeyPairType;
  notBoldKey?: boolean;
}

const KeyPairTypeStyle = ({
  theme,
  type,
}: {
  theme: DefaultTheme;
  type?: KeyPairType;
}) => {
  switch (type) {
    case 'secondary':
      return css`
        color: ${theme.colors.primaryLightBlue};
        font-size: 1.2rem;
      `;
    case 'primary':
    default:
      return css`
        color: inherit;
        font-size: inherit;
      `;
  }
};

const KeyPairInfo = styled.div<KeyPairProps>`
  display: flex;
  align-items: center;
  ${KeyPairTypeStyle}

  *:first-child {
    font-weight: ${(p) => (p.notBoldKey ? 'inherit' : '600')};
    margin-right: 0.5rem;
  }
`;

export default KeyPairInfo;
