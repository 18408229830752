import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveWarningModal as BaseSaveWarningModal } from '@abc-intelligence/abc-ui';

import { useSaveConsult, useStore, useOrderAll } from 'hooks';

interface SaveWarningModalProps {
  onCancel: () => void;
  onContinue: () => void;
  show: boolean;
}

const SaveWarningModal: React.FC<SaveWarningModalProps> = ({
  onCancel,
  onContinue,
  show,
}) => {
  const { t } = useTranslation();
  const { savePage, saveStatus, clear } = useSaveConsult();
  const {
    state: {
      consult: { activeConsultId },
    },
    bindActions,
  } = useStore();
  const { isInOrders, setOrderAll } = useOrderAll();

  const continueHandler = useCallback(() => {
    const { setHasChanged } = bindActions();
    setHasChanged({ id: activeConsultId, hasChanged: false });
    onContinue();
  }, [bindActions, onContinue, activeConsultId]);

  useEffect(() => {
    if (saveStatus === 'resolved') {
      continueHandler();
      clear();
    }
  }, [saveStatus, continueHandler, clear]);

  const saveOrOrderHandler = () => {
    if (isInOrders) {
      setOrderAll({
        isAllOrdered: true,
        comingRoute: '',
      });
      onCancel();
    } else {
      savePage();
    }
  };

  const isSaving = saveStatus === 'pending';

  return (
    <BaseSaveWarningModal
      show={show}
      modalTitle={t('general.saveWarningTitle') as string}
      modalDescription={t('general.saveWarningDescription') as string}
      saveBtnTitle={
        isInOrders
          ? (t('general.orderAll') as string)
          : (t('general.saveAndContinue') as string)
      }
      continueBtnTitle={t('general.continueWithoutSave') as string}
      onCloseHandler={onCancel}
      onContinueHandler={continueHandler}
      onSaveHandler={saveOrOrderHandler}
      isSaving={isSaving}
    />
  );
};

export default SaveWarningModal;
