import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InitialConsultI,
  ConsultStateI,
  UpdateConsultValueType,
  AppointmentStatusT,
} from 'base/types';
import { DashboardPages } from 'consts';
import {
  addConsultHelper,
  removeConsultHelper,
  hasChangedHelper,
  updateConsultHandler,
  getConsultIndex,
  setConsultHelper,
  setConsultStatusHelper,
} from './utils';

const INITIAL_STATE: InitialConsultI = {
  openConsults: [],
  activeConsultId: undefined,
  activeConsultIndex: -1,
};

export const consultSlice = createSlice({
  name: 'consult',
  initialState: INITIAL_STATE,
  reducers: {
    addConsult: (state, { payload }: PayloadAction<ConsultStateI>) => {
      addConsultHelper(payload, state);
    },
    setActiveConsultId: (
      state,
      { payload }: PayloadAction<{ id: ConsultStateI['consult_id'] }>
    ) => {
      state.activeConsultId = payload.id;
      state.activeConsultIndex = getConsultIndex(
        state.openConsults,
        payload.id
      );
    },
    removeConsult: (
      state,
      { payload }: PayloadAction<{ id: ConsultStateI['consult_id'] }>
    ) => {
      removeConsultHelper(state, payload.id);
    },
    setHasChanged: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: ConsultStateI['consult_id'];
        hasChanged: ConsultStateI['hasChanged'];
      }>
    ) => {
      hasChangedHelper(state, payload.id, payload.hasChanged);
    },
    updateConsult: (
      state,
      {
        payload,
      }: PayloadAction<{
        dashboardPage: DashboardPages;
        value: UpdateConsultValueType;
        hasChanged?: boolean;
      }>
    ) => {
      updateConsultHandler(
        state,
        payload.dashboardPage,
        payload.value,
        payload.hasChanged
      );
    },
    setConsult: (
      state,
      { payload }: PayloadAction<{ consult: ConsultStateI; index: number }>
    ) => {
      setConsultHelper(state, payload.consult, payload.index);
    },
    setConsultStatus: (
      state,
      {
        payload,
      }: PayloadAction<{ consultIndex: number; status: AppointmentStatusT }>
    ) => {
      setConsultStatusHelper(state, payload.consultIndex, payload.status);
    },
  },
});

export const {
  setConsult,
  addConsult,
  removeConsult,
  setActiveConsultId,
  setHasChanged,
  updateConsult,
  setConsultStatus,
} = consultSlice.actions;
export default consultSlice.reducer;
