import styled, { css, DefaultTheme } from 'styled-components';

import { Direction, TooltipType } from './Tooltip';

const TooltipArrowSize = '0.6rem';
const TooltipMargin = '1rem';

const getTooltipCss = (
  direction: Direction,
  theme: DefaultTheme,
  type?: TooltipType
) => {
  switch (direction) {
    case 'top':
      return css`
        /* Absolute positioning */
        top: calc(-100% - ${TooltipMargin});

        /* CSS border triangles */
        &::before {
          top: 100%;
          border-top-color: ${type === 'danger'
            ? theme.colors.secondaryRed
            : type === 'warning'
            ? theme.colors.secondaryOrange
            : theme.colors.secondaryBlue};
        }
      `;
    case 'right':
      return css`
        /* Absolute positioning */
        left: calc(100% + ${TooltipMargin});
        top: 50%;
        transform: translateX(0) translateY(-50%);

        /* CSS border triangles */
        &::before {
          left: calc(${TooltipArrowSize} * -1);
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-right-color: ${type === 'danger'
            ? theme.colors.secondaryRed
            : type === 'warning'
            ? theme.colors.secondaryOrange
            : theme.colors.secondaryBlue};
        }
      `;

    case 'bottom':
      return css`
        /* Absolute positioning */
        bottom: calc(${TooltipMargin} * -3);

        &::before {
          bottom: 100%;
          border-bottom-color: ${type === 'danger'
            ? theme.colors.secondaryRed
            : type === 'warning'
            ? theme.colors.secondaryOrange
            : theme.colors.secondaryBlue};
        }
      `;
    case 'left':
      return css`
        /* Absolute positioning */
        left: auto;
        right: calc(100% + ${TooltipMargin});
        top: 50%;
        transform: translateX(0) translateY(-50%);

        /* CSS border triangles */
        &::before {
          left: auto;
          right: calc(${TooltipMargin} * -1);
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-left-color: ${type === 'danger'
            ? theme.colors.secondaryRed
            : type === 'warning'
            ? theme.colors.secondaryOrange
            : theme.colors.secondaryBlue};
        }
      `;
    default:
      return css``;
  }
};

/* Wrapping */
export const TooltipWrapper = styled.div`
  display: flex;
  position: relative;
`;

/* Absolute positioning */
export const TooltipTip = styled.div<{
  direction: Direction;
  type?: TooltipType;
  textFontSize?: string;
  maxWidth?: string;
}>`
  position: absolute;
  border-radius: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.colors.primaryWhite};
  background: ${({ theme, type }) =>
    type === 'danger'
      ? theme.colors.secondaryRed
      : type === 'warning'
      ? theme.colors.secondaryOrange
      : theme.colors.secondaryBlue};
  font-size: ${(p) => p.textFontSize || '1rem'};
  line-height: 1;
  padding: 0.4rem;
  white-space: ${({ maxWidth }) => (maxWidth ? 'normal' : 'nowrap')};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  width: max-content;

  /* CSS border triangles */
  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${TooltipArrowSize};
    margin-left: calc(${TooltipArrowSize} * -1);
  }

  ${(p) => getTooltipCss(p.direction, p.theme, p.type)}
`;
