import { useStore } from 'hooks';
import { yupValidate, notEmptyStrValidate } from './utils';

type InputValidationT = 'email' | 'notEmpty' | 'phoneNumber';

const useInputValidation = () => {
  const { bindActions } = useStore();

  const { setInputValidationError } = bindActions();

  const inputValidationHandler = async (
    inputValue: string | null | undefined,
    validationKey: string,
    validationType: InputValidationT,
    errorTranslationCode: string
  ) => {
    let isValid = true;
    inputValue = inputValue?.trim() || inputValue;

    switch (validationType) {
      case 'email':
        isValid = await yupValidate('email', inputValue);
        // errorTCode = !isValid ? 'email' : '';
        break;
      case 'notEmpty':
        isValid = notEmptyStrValidate(inputValue);
        // errorTCode = !isValid ? 'phoneNumber' : '';
        break;
      case 'phoneNumber':
        isValid = await yupValidate('phoneNumber', inputValue);
        // errorTCode = !isValid ? 'phoneNumber' : '';
        break;
      default:
        throw new Error(`Unhandled inputName`);
    }

    errorTranslationCode = !isValid ? errorTranslationCode : '';

    setInputValidationError({ validationKey, errorTranslationCode });
  };

  return inputValidationHandler;
};

export default useInputValidation;
