import styled from 'styled-components';

export const PatientName = styled.p`
  display: block;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
`;

export const SiteDropdownWrapper = styled.div`
  min-width: 20rem;
  padding-right: 5px;
`;
