import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageSpinner } from '@abc-intelligence/abc-ui';

import { envConfig } from 'base/config';
import { isInFrontPage, navigateTo403 } from 'utils';
import { useStore, useApi, usePGPermissions } from 'hooks';
import { GET_USER_URL } from 'base/api';
import { UserI } from 'base/types';
import { Tabs, Navbar, ErrorToast, SuccessToast } from './components';
import { LayoutContainer } from './Layout.style';

interface LayoutProps {
  children: JSX.Element;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const [abcLogoClicked, setAbcLogoClicked] = useState(false);
  const {
    bindActions,
    state: { loading, user },
  } = useStore();
  const location = useLocation();

  const { sendRequest } = useApi();
  const getUser = useCallback(async () => {
    const { setError, setUser } = bindActions();
    if (user === null) {
      try {
        const newUser = await sendRequest({
          baseURL: `${envConfig.BASE_API}${GET_USER_URL}`,
        });
        setUser(newUser as UserI);
      } catch (error) {
        setError(t('error.generalError'));
      }
    }
  }, [sendRequest, user, t, bindActions]);

  const { canAccessApp } = usePGPermissions(user);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (user && !canAccessApp()) {
      navigateTo403();
    }
  }, [canAccessApp, user]);

  const abcLogoClickHandler = useCallback(() => {
    const pathname = location.pathname;
    if (!isInFrontPage(pathname)) {
      setAbcLogoClicked(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const pathname = location.pathname;
    if (isInFrontPage(pathname)) {
      setAbcLogoClicked(false);
    }
  }, [location.pathname]);

  if (!canAccessApp()) return null;

  return (
    <div>
      <PageSpinner loading={loading} />
      {user && (
        <>
          <Navbar abcLogoClickHandler={abcLogoClickHandler} />
          <ErrorToast />
          <SuccessToast />
          <LayoutContainer>{children}</LayoutContainer>
          <Tabs abcLogoClicked={abcLogoClicked} />
        </>
      )}
    </div>
  );
};

export default Layout;
