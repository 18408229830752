import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE = '';

const errorReducer = createSlice({
  name: 'error',
  initialState: INITIAL_STATE,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => payload,
    removeError: () => '',
  },
});

export const { setError, removeError } = errorReducer.actions;
export default errorReducer.reducer;
