import { createContext, useCallback, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FrontPageStateI,
  FrontPageProviderProps,
  FrontPageApiActionsT,
} from './types';
import frontPageReducer from './reducer';
import {
  fetchConsultsAndAppointments,
  fetchAppointments,
  fetchDropdownData,
  updateDropdown,
  setPin,
} from './apiActions';
import { useStore } from 'hooks';
import { getCurrentTime, getShiftPeriod } from 'utils';
import { DropdownOptions, DropdownValues } from 'base/types';

const DropdownInitial = {
  case_owners: [],
  departments: [],
  shifts: [],
};

const FrontPageInitialState: FrontPageStateI = {
  lastRefreshTime: '',
  hospitalConsultList: [],
  waitingRoomConsultList: [],
  appointments: [],
  dropdownOptions: DropdownInitial,
  dropdownValues: DropdownInitial,
  pageLoading: false,
  appointmentsLoading: false,
  shiftsText: '',
};

const FrontPageContext =
  createContext<
    | {
        frontPageState: FrontPageStateI;
        dispatchFrontPageContext: (action: FrontPageApiActionsT) => void;
      }
    | undefined
  >(undefined);

const FrontPageProvider = ({ children }: FrontPageProviderProps) => {
  const [frontPageState, dispatchState] = useReducer(
    frontPageReducer,
    FrontPageInitialState
  );
  const { bindActions } = useStore();
  const { t } = useTranslation();

  const fetchInitialState = useCallback(
    async (
      dropdownValues: DropdownValues,
      dropdownOptions?: DropdownOptions
    ) => {
      const { hospitalConsultList, waitingRoomConsultList } =
        await fetchConsultsAndAppointments(dropdownValues);

      const appointments = await fetchAppointments(dropdownValues);

      dispatchState({
        type: 'setInitialState',
        payload: {
          lastRefreshTime: getCurrentTime(),
          hospitalConsultList,
          waitingRoomConsultList,
          appointments,
          pageLoading: false,
          appointmentsLoading: false,
          shiftsText: getShiftPeriod(dropdownValues.shifts),
        },
      });

      dispatchState({
        type: 'setAllDropdownValues',
        payload: dropdownValues,
      });

      if (dropdownOptions) {
        dispatchState({
          type: 'setDropdownOptions',
          payload: dropdownOptions,
        });
      }
    },
    []
  );

  const dispatchFrontPageContext = useCallback(
    async (action: FrontPageApiActionsT) => {
      try {
        switch (action.type) {
          case 'fetchInitialState':
            dispatchState({ type: 'setPageLoading', payload: true });
            const { dropdownOptions, dropdownValues } =
              await fetchDropdownData();
            await fetchInitialState(dropdownValues, dropdownOptions);
            break;
          case 'setDropdownValue':
            dispatchState({
              type: 'setDropdownValue',
              payload: action.payload,
            });
            break;
          case 'updateDropdown':
            const { dropdownKey, newValue } = action.payload;
            await updateDropdown(newValue);
            if (dropdownKey === 'shifts') {
              dispatchState({
                type: 'setAppointmentsLoading',
                payload: true,
              });
              const appointments = await fetchAppointments(newValue);
              dispatchState({
                type: 'setAppointments',
                payload: {
                  appointments,
                  shifts: newValue.shifts,
                  shiftsText: getShiftPeriod(newValue.shifts),
                },
              });
            } else {
              dispatchState({ type: 'setPageLoading', payload: true });
              await fetchInitialState(newValue);
            }
            break;
          case 'setPin':
            const { payload } = action;
            await setPin(payload);
            dispatchState({
              type: 'setPin',
              payload,
            });
            break;
          default: {
            throw new Error(`Unhandled action type`);
          }
        }
      } catch (err) {
        const { setError } = bindActions();
        setError(t('error.generalError'));
      }
    },
    [fetchInitialState, bindActions, t]
  );

  const value = { frontPageState, dispatchFrontPageContext };

  return (
    <FrontPageContext.Provider value={value}>
      {children}
    </FrontPageContext.Provider>
  );
};

const useFrontPageContext = () => {
  const context = useContext(FrontPageContext);
  if (context === undefined) {
    throw new Error(
      'useFrontPageContext must be used within a FrontPageProvider'
    );
  }
  return context;
};

export { FrontPageProvider, useFrontPageContext };
