import React, { useCallback } from 'react';
import { AsyncDropdown, DropdownOptionType } from '@abc-intelligence/abc-ui';

import { ModalityI, ProblemI, RuleOutI } from 'base/types';
import {
  getSearchModalitiesURL,
  getProblemListDropdownURL,
} from 'base/api/urls';
import { useStore, useGetActiveConsult, useApi } from 'hooks';
import {
  getCleanQuery,
  getUniqueOptions,
  getOptions,
  OptionsType,
  doesQueryExist,
} from 'utils';
import { DropdownWrapper } from 'components';
import { asyncDropdownStyle } from './DecAsyncDropdown.style';

interface DecAsyncDropdownProps {
  optionsType: OptionsType;
  onSelect: (option: DropdownOptionType) => void;
  defaultOptionsArray?: ModalityI[] | RuleOutI[];
  placeholder?: string;
}

const DecAsyncDropdown: React.FC<DecAsyncDropdownProps> = ({
  optionsType,
  onSelect,
  defaultOptionsArray,
  placeholder,
}) => {
  const { sendRequest } = useApi();
  const activeConsult = useGetActiveConsult();
  const {
    state: { animal },
  } = useStore();

  const getDefaultOptions = useCallback(():
    | DropdownOptionType[]
    | undefined => {
    if (!defaultOptionsArray) return [];
    switch (optionsType) {
      case 'estimate_modality':
        return getOptions(
          (defaultOptionsArray as ModalityI[]).filter(
            (dop) =>
              !activeConsult.estimate!.modalities.find(
                (e) => e.product_name === dop.product_name
              )
          ),
          'estimate_modality'
        ).map((dfo) => ({ ...dfo, isDefault: true }));
      case 'rule-outs':
        if (!activeConsult.rule_outs?.rule_outs) return [];
        return getOptions(
          (defaultOptionsArray as RuleOutI[]).filter(
            (dop) =>
              !activeConsult.rule_outs?.rule_outs.find(
                (r) => r.name === dop.name
              )
          ),
          'rule-outs'
        ).map((dfo) => ({ ...dfo, isDefault: true }));
      default:
        return [];
    }
  }, [
    optionsType,
    activeConsult.rule_outs?.rule_outs,
    activeConsult.estimate,
    defaultOptionsArray,
  ]);

  const getUrl = useCallback(
    (query: string): string => {
      const cleanQuery = getCleanQuery(query);

      switch (optionsType) {
        case 'estimate_modality':
          return getSearchModalitiesURL(
            activeConsult.clinic_id as number,
            cleanQuery
          );
        case 'rule-outs':
        case 'problemList_dropdown':
          return getProblemListDropdownURL(
            activeConsult.clinic_id!,
            animal?.species!,
            cleanQuery
          );
        default:
          return '';
      }
    },
    [activeConsult.clinic_id, optionsType, animal?.species]
  );

  const getResponseOptions = useCallback(
    (response: any) => {
      switch (optionsType) {
        case 'estimate_modality':
          return response.modalities;
        case 'rule-outs':
        case 'problemList_dropdown':
          return response.problems;
        default:
          return;
      }
    },
    [optionsType]
  );

  const createNewOption = useCallback(
    (responseOptions: any, query: string) => {
      let newOption: RuleOutI | ProblemI | undefined;

      switch (optionsType) {
        case 'rule-outs':
          const ruleOutsNames = responseOptions!.map(
            (p: RuleOutI) => p.name
          ) as string[];
          if (!doesQueryExist(ruleOutsNames, query)) {
            newOption = {
              name: query,
              has_blackwell_documentation: false,
              has_clinic_documentation: false,
            } as RuleOutI;
          }
          break;
        case 'problemList_dropdown':
          const problemsNames = responseOptions!.map(
            (p: ProblemI) => p.name
          ) as string[];
          if (!doesQueryExist(problemsNames, query)) {
            newOption = {
              name: query,
              comments: null,
            } as ProblemI;
          }
          break;
        default:
          break;
      }

      return newOption;
    },
    [optionsType]
  );

  const createUniqueOptions = useCallback(
    (responseOptions: any[], newOption: any): DropdownOptionType[] => {
      const allOptions = newOption ? getOptions([newOption], optionsType) : [];
      let selectedOptions: DropdownOptionType[] = [];

      switch (optionsType) {
        case 'estimate_modality':
          allOptions.push(...getOptions(responseOptions, 'estimate_modality'));
          selectedOptions = getOptions(
            activeConsult?.estimate?.modalities,
            'estimate_modality'
          );
          break;
        case 'rule-outs':
          allOptions.push(
            ...getOptions(responseOptions, 'problemList_dropdown')
          );
          selectedOptions = getOptions(
            activeConsult?.rule_outs?.rule_outs || [],
            'rule-outs'
          );
          break;
        case 'problemList_dropdown':
          allOptions.push(
            ...getOptions(responseOptions, 'problemList_dropdown')
          );
          selectedOptions = getOptions(
            activeConsult?.problem_list?.selected_problems.problems || [],
            'problemList_dropdown'
          );
          break;
        default:
          break;
      }

      const uniqueOptions = getUniqueOptions(allOptions, selectedOptions);

      return uniqueOptions;
    },
    [
      optionsType,
      activeConsult?.estimate?.modalities,
      activeConsult?.rule_outs?.rule_outs,
      activeConsult?.problem_list?.selected_problems.problems,
    ]
  );

  const loadOptions = useCallback(
    async (query: string) => {
      try {
        const url = getUrl(query);
        const response = await sendRequest({
          url,
          method: 'GET',
        });
        const responseOptions = getResponseOptions(response);
        const newOption = createNewOption(responseOptions, query);

        const uniqueOptions = createUniqueOptions(responseOptions, newOption);

        return uniqueOptions;
      } catch {
        return [];
      }
    },
    [
      getUrl,
      getResponseOptions,
      sendRequest,
      createNewOption,
      createUniqueOptions,
    ]
  );

  return (
    <DropdownWrapper>
      <AsyncDropdown
        debounceLoadOptions={loadOptions}
        defaultOptions={getDefaultOptions()}
        oneSelectHandler={onSelect}
        value={null}
        menuPosition="fixed"
        styles={asyncDropdownStyle}
        placeholder={placeholder}
      />
    </DropdownWrapper>
  );
};

export default DecAsyncDropdown;
