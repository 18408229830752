import styled, { CSSObject, DefaultTheme } from 'styled-components';

export const TabsContainer = styled.div`
  width: max-content;
  background: transparent;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: start;
  overflow-x: auto;
  z-index: 15;
  height: ${(p) => p.theme.sizes.footer_height};
`;

const getActiveCss = (theme: DefaultTheme, active?: boolean): CSSObject => {
  if (!active) return {};

  return {
    boxShadow: ` 0 1rem 2rem ${theme.colors.primaryBlue}`,
    transform: 'translateY(-0.7px)',
  };
};

export const Tab = styled.button<{ active?: boolean }>(({ active, theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, max-content)',
  gap: '0 1.2rem',
  alignItems: 'center',
  padding: '1rem',
  fontSize: '1.1rem',
  color: theme.colors.primaryWhite,
  border: `solid 1px ${theme.colors.primaryWhite}`,
  borderBottom: 'none',
  borderTop: 'none',
  background: active ? theme.colors.primary700 : theme.colors.main900,
  '&, &:hover, &:focus , &:active': {
    outline: 'none',
  },
  '&:hover': {
    outline: 'none',
    background: theme.colors.primary200,
    color: theme.colors.main900,
  },
  ...getActiveCss(theme, active),
}));

export const AddTab = styled(Tab)`
  display: inline-block;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.main900};
  }
`;
