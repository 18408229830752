import { css } from 'styled-components';

export const DeleteItemCSS = css`
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.secondaryRed};
  }
`;

export const noScrollBar = () => css`
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;
