import React from 'react';
import styled from 'styled-components';

const CheckboxLabel = styled.label`
  margin-left: 1rem;
`;

interface CheckboxProps {
  id: string;
  label: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  checked,
  onChange,
  className,
}) => {
  return (
    <div className={className}>
      <input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
    </div>
  );
};

export default Checkbox;
