import {
  getCommonEnvConfig,
  CommonENVConfigT,
  AppStageT,
} from '@abc-intelligence/abc-ui';

type ENVConfigT = CommonENVConfigT & {
  APP_STAGE: string;

  BASE_API: string;
  BASE_EMR: string;
};

const { REACT_APP_STAGE } = process.env;

const baseConfig = {
  APP_STAGE: REACT_APP_STAGE!,
  ...getCommonEnvConfig(REACT_APP_STAGE as AppStageT),
};

const getEnvConfig = (): ENVConfigT => {
  switch (REACT_APP_STAGE) {
    case 'prod':
      return {
        ...baseConfig,

        BASE_API: 'https://portal-api.vetgenus.com',
        BASE_EMR: 'https://emr-api.vetgenus.com',
      };
    case 'staging':
      return {
        ...baseConfig,

        BASE_API: 'https://portal-api-staging.vetgenus.com',
        BASE_EMR: 'https://emr-api-staging.vetgenus.com',
      };
    case 'dev':
      return {
        ...baseConfig,

        BASE_API: 'https://portal-api-dev.vetgenus.com',
        BASE_EMR: 'https://emr-api-dev.vetgenus.com',
      };
    case 'preview':
      return {
        ...baseConfig,

        BASE_API: 'https://portal-api-dev.vetgenus.com',
        BASE_EMR: 'https://emr-api-dev.vetgenus.com',
      };
    default:
      // local
      return {
        ...baseConfig,

        BASE_API: 'https://portal-api-dev.vetgenus.com',
        BASE_EMR: 'https://emr-api-dev.vetgenus.com',
      };
  }
};

const envConfig = getEnvConfig();

export default envConfig;
