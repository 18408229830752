import React, { useEffect } from 'react';

import { sizes } from 'base/styles/variables';
import { useStore } from 'hooks';
import Toast from '../Toast.style';

interface SuccessToastProps {}

const SuccessToast: React.FC<SuccessToastProps> = () => {
  const {
    state: { success },
    bindActions,
  } = useStore();
  const { removeSuccess } = bindActions();

  useEffect(() => {
    if (success) {
      const { removeSuccess, setError } = bindActions();
      setError('');
      setTimeout(() => removeSuccess(), 2000);
    }
  }, [success, bindActions]);

  return (
    <Toast
      headerHeight={sizes.header_height}
      message={success}
      onClose={removeSuccess}
      toastType="success"
    />
  );
};

export default SuccessToast;
